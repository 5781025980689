import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 15px;
  .nomeInput {
    grid-area: 1 / 1 / 1 / 3;
    justify-self: stretch;
  }
  padding-bottom: 50px;
  span {
    color: #f6f6f6;
  }
  .divButton {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 450px) {
      flex-direction: column;
      a {
        display: flex;
        button {
          flex: 1;
        }
      }
    }
  }

  .pageButton {
    margin: 13px 12px 12px 10px;
    height: 44px;
    background: #e73739;
    font-weight: bold;
    min-width: 120px;
    color: #f6f6f6;
    border: 0;
    border-radius: 25px 25px 25px 25px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, '#e73739')};
    }
    @media screen and (max-width: 450px) {
      margin: 20px;
    }
  }
  header {
    margin-top: 30px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    h2 {
      color: #f5f5f5;
      text-align: justify;
    }
    .steps {
      width: 100%;
    }
    h3 {
      font-size: 17px;
      color: #f5f5f5;
      text-align: justify;
      line-height: 1.5em;
    }
    ul {
      margin: 30px auto;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    .step {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px 0 0;
      width: 130px;
      height: 44px;
      background: #f5f5f5;
      font-weight: bold;
      color: #221e35;
      border: 0;
      border-radius: 25px 25px 25px 25px;
      font-size: 16px;
    }
    .selected {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px 0 0;
      width: 180px;
      height: 62px;
      background: #f5f5f5;
      font-weight: bold;
      color: #221e35;
      border: 0;
      border-radius: 25px 25px 25px 25px;
      font-size: 20px;
    }
    @media screen and (max-width: 640px) {
      ul {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  display: flex;
  flex-direction: column;
`;

import * as Yup from 'yup';

interface AdType {
  week_days: string[];
  description?: string;
  start_hour: string;
  whatsapp?: string;
  end_hour: string;
}

Yup.setLocale({ mixed: { notType: 'Valor inválido' } });

const test = (values: string[]): boolean =>
  !(values.includes('7') && values.length > 1);

const options = {
  name: 'testEveryDay',
  test,
  message: 'Não pode selecionar "todos os dias" com outras opções',
};

export const schema = Yup.object().shape<AdType>({
  week_days: Yup.array<string>()
    .min(1, 'Pelo menos um dia deve ser escolhido')
    .test(options),
  description: Yup.string().max(300, 'Tamanho máximo de 300 caracteres'),
  start_hour: Yup.string().required('Hora inicial é obrigatória'),
  whatsapp: Yup.string(),
  end_hour: Yup.string().required('Hora final é obrigatória'),
});

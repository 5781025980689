import React, { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import Input from '../../components/Input';
import api from '../../services/api';
import logo from '../../assets/logotipo.png';

// import { Container } from './styles';

interface DataContent {
  email: string;
}

export default function ForgotPassword(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const handleSubmit = useCallback(
    async (data: DataContent) => {
      const { email } = data as DataContent;
      try {
        setLoading(true);
        await api.post('/restaurants-password/forgot', { email });
        history.push('/');
        toast.success('Email enviado com sucesso!');
      } catch (error) {
        toast.error('Falha ao enviar E-mail. Favor verificar dados.');
      } finally {
        setLoading(false);
      }
    },
    [history]
  );

  return (
    <>
      <img src={logo} width="250" alt="appy-hour" />

      <Form onSubmit={handleSubmit} translate="no">
        <Input
          type="email"
          className="usual"
          placeholder="Seu e-mail*"
          name="email"
        />

        <button type="submit" disabled={loading}>
          {loading ? 'Enviando...' : 'Enviar'}
        </button>
        <Link to="/">Voltar</Link>
      </Form>
    </>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  button {
    background-color: #221e35;
    border: none;
  }
  .Menu {
    display: flex;
    justify-content: space-between;
    img {
      margin-right: 20px;
      padding-right: 20px;
      height: 64px;
    }
  }
  .items {
    display: flex;
    position: absolute;
    top: 100%;
    width: 100%;
    flex: 1;
    margin-bottom: 8px;
    ul {
      display: flex;
      flex: 1;
      flex-direction: column;
      li {
        color: #f6f6f6;
        display: block;
        text-align: center;
        padding: 10px 0;
        margin: 2px 0;
        background-color: #e73739;
        border-radius: 4px;
        font-weight: bold;
      }
      a {
        display: block;
        margin-top: 2px;
        font-size: 16px;
        color: #f6f6f6;
      }
      button {
        margin-top: 2px;
        font-size: 16px;
        color: #f6f6f6;
        background-color: #e73739;
        font-weight: bold;
      }
    }
  }
`;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useCallback } from 'react';
import { cpf } from 'cpf-cnpj-validator';
import { FormHandles } from '@unform/core';
import { useHistory, Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { useSignUp } from '../../../../hooks/signUp';
import Input from '../../../../components/Input';
import InputMask from '../../../../components/InputMask';
import PhoneInput from '../../../../components/PhoneInput';
import logo from '../../../../assets/logotipo.png';
import { schemaOwner } from '../../schemas/schemas';
import ValidationTypeError from '../../../../errors/ValidationTypeError';

interface Props {
  initialData: object;
}

interface RestaurantOwner {
  cpf: string;
  name: string;
  phone: string;
  email: string;
}

interface Error {
  path: string;
  message: string;
}

interface ValidationError {
  [key: string]: string;
}

export default function FormOwner(): JSX.Element {
  const { restaurant, updateRestaurant } = useSignUp();
  const formRef = useRef<FormHandles>(null);

  const classOne = 'form-progress-indicator one';
  const classTwo = 'form-progress-indicator two';
  const classThree = 'form-progress-indicator three active';
  const classFour = 'form-progress-indicator four';
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: RestaurantOwner) => {
      try {
        // eslint-disable-next-line no-unused-expressions
        formRef && formRef.current && formRef.current.setErrors({});
        await schemaOwner.validate(data, {
          abortEarly: false,
        });

        if (!cpf.isValid(data.cpf)) {
          throw new ValidationTypeError('cpf');
        }
        restaurant.owner = {
          ...(data as RestaurantOwner),
        };

        updateRestaurant(restaurant);
        history.push('register-checks');
      } catch (error) {
        const validationErrors: ValidationError = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err: Error) => {
            validationErrors[err.path] = err.message;
          });
          // eslint-disable-next-line no-unused-expressions
          formRef &&
            formRef.current &&
            formRef.current.setErrors(validationErrors);
          return;
        }
        if (error instanceof ValidationTypeError) {
          validationErrors[error.field] = error.message;
          // eslint-disable-next-line no-unused-expressions
          formRef &&
            formRef.current &&
            formRef.current.setErrors(validationErrors);
        }
      }
    },
    [updateRestaurant, history, restaurant]
  );

  return (
    <>
      <img src={logo} width="250" alt="appy-hour" />

      <div className="form-progress">
        <progress className="form-progress-bar" max="100" value={66} />

        <div className={classOne} />
        <div className={classTwo} />
        <div className={classThree} />
        <div className={classFour} />
      </div>
      <h3>Responsável pelo Estabelecimento</h3>
      <Form
        initialData={restaurant.owner}
        onSubmit={handleSubmit}
        translate="a"
        ref={formRef}
      >
        <Input className="usual" name="name" placeholder="Nome*" />
        <InputMask
          name="cpf"
          mask="999.999.999-99"
          placeholder="CPF*"
          className="usual"
        />
        <PhoneInput name="phone" placeholder="Telefone*" className="usual" />
        <Input
          name="email"
          className="usual"
          placeholder="E-mail*"
          type="email"
        />
        <span>Todos os campos são obrigatórios</span>
        <br />

        <div className="divButtonPage">
          <button
            type="button"
            onClick={(): void => history.push('register-address')}
          >
            Voltar
          </button>
          <button type="submit">Avançar</button>
        </div>
      </Form>
      <Link to="/">Já tem conta?</Link>
      <br />
      <br />
      <br />
      <br />
    </>
  );
}

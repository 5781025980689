import React, { useEffect, useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { toast } from 'react-toastify';
import { MdReplay } from 'react-icons/md';
import api from '../../services/api';
import Category from './Category';

import { Container } from './styles';

interface Photo {
  url: string;
}

interface Category {
  id: string;
  restaurant_id: number;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  menuItens: MenuItem[];
}

interface MenuItem {
  id: string;
  category_id: number;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  description: string;
  normal_price: string;
  happy_hour_price: string;
}

export default function AdViewer(): JSX.Element {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [days, setDays] = useState<number[]>([]);
  const [hour, setHour] = useState('');
  const [categories, setCategories] = useState<Category[]>([]);
  const [adDescription, setAdDescription] = useState('');
  const [whatsappData, setWhatsappData] = useState('');
  const daysString = [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
    'Todos os dias',
  ];
  useEffect(() => {
    async function loadData(): Promise<void> {
      try {
        const response = await api.get('/ads-edit');
        const ad = response.data;
        setPhotos(ad.images);

        const { description, week_days, start_hour, end_hour, whatsapp } = ad;

        if (week_days) {
          setDays(week_days);
        }

        if (start_hour && end_hour) {
          setHour(`${start_hour} às ${end_hour}`);
        }

        if (description) {
          setAdDescription(description);
        }

        if (whatsapp) {
          setWhatsappData(whatsapp);
        }

        const responseCategories = await api.get('/categories-edit');

        setCategories(responseCategories.data);
      } catch (err) {
        toast.error('Falha ao atualizar informações. Verifique a conexão');
      }
    }
    loadData();
  }, []);
  const publish = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      await api.post('/publish');
      toast.success('Sucesso ao publicar seu anúncio');
      history.push('/');
    } catch (err) {
      toast.error('Falha ao publicar');
    } finally {
      setLoading(false);
    }
  }, [history]);
  return (
    <Container>
      {loading ? (
        <div className="loading">
          <MdReplay color="f6f6f6" size="60" />
          <span>Aguarde, publicando</span>
        </div>
      ) : (
        <>
          <header>
            <h2>Chegamos ao último passo.</h2>
            <br />
            <br />
            <h3>
              Nesta etapa, você irá visualizar todas as informações cadastradas
              até agora. Se estiver do seu agrado, clique em "Publicar".
              Automaticamente, o cardápio ficará visível para os seus clientes
              no aplicativo mobile, caso contrário, você poderá editar as
              informações clicando no número do passo que será alterado.
            </h3>

            <div className="steps">
              <ul>
                <li className="step">1º Passo</li>
                <li className="step">2º Passo</li>
                <li className="step">3º Passo</li>
                <li className="selected">4º Passo</li>
              </ul>
            </div>
          </header>
          <Carousel showThumbs={false} showStatus={false}>
            {photos.map((photo) => (
              <div className="preview" key={photo.url}>
                <img src={photo.url} alt="menu" />
              </div>
            ))}
          </Carousel>

          <div className="time2">
            <h3>
              Horário do Happy Hour das
              {'\u00A0'}
              {hour}
            </h3>
          </div>

          <div className="description">
            <h5>{adDescription}</h5>
          </div>
          <div className="days">
            <ul>
              {days.map((day) => (
                <li key={daysString[day]}>{daysString[day]}</li>
              ))}
            </ul>
          </div>
          <div className="categories">
            {categories.map((category) => (
              <Category category={category} key={category.name} />
            ))}
          </div>
          {whatsappData && (
            <div className="whatsapp">
              <span>Contato para entrega:</span>
              <h6>{whatsappData}</h6>
            </div>
          )}

          <div className="divButton">
            <Link to="/DataAd">
              <button type="button" className="pageButton">
                Voltar
              </button>
            </Link>
            <button type="button" className="pageButton" onClick={publish}>
              {' '}
              Publicar
            </button>
          </div>
        </>
      )}
    </Container>
  );
}

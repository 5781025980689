/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import { Carousel } from 'react-responsive-carousel';
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useField } from '@unform/core';
import { MdLocalSee, MdAddCircleOutline, MdRefresh } from 'react-icons/md';
import { toast } from 'react-toastify';
import api from '../../services/api';
import compressImage from '../../services/compression';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Container } from './styles';

interface Props {
  source?: string[];
  edit_ad_id: string;
  name: string;
}

interface Preview {
  url: string;
  id: string;
}

export default function ImagePicker({
  source,
  edit_ad_id,
  ...rest
}: Props & React.HTMLAttributes<HTMLDivElement>): JSX.Element {
  const [loading, setLoading] = useState(false);

  const { defaultValue, registerField, error } = useField('file_ids');

  const [images, setImages] = useState<Preview[]>([]);

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (defaultValue) {
      setImages(defaultValue);
    }
  }, [defaultValue]);
  useEffect(() => {
    if (ref.current) {
      registerField({
        name: 'file_ids',
        ref: ref.current,
        path: 'value',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultValue) {
      setImages(defaultValue);
    }
  }, [defaultValue, source]);

  const removeImg = useCallback(
    async (index: string): Promise<void> => {
      try {
        setLoading(true);
        await api.delete(`ads-edit/${edit_ad_id}/images/${index}`);

        setImages(images.filter((view) => view.id !== index));
      } catch (err) {
        toast.error('Falha ao deletar a imagem');
      } finally {
        setLoading(false);
      }
    },
    [edit_ad_id, images]
  );

  const value = useMemo(() => images.map((image) => image.id), [images]);

  const handleChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
      try {
        setLoading(true);
        const data = new FormData();
        const imagesAdded: { id: string; url: string }[] = [];
        data.append('file', 'name');
        if (e.target.files) {
          const { files } = e.target;
          if (files.length + images.length > 7) {
            toast.error('Máximo de sete imagens');
            return;
          }
          for (let i = 0; i < files.length; i++) {
            if (files[i].size > 4194304) {
              toast.error('Tamanho máximo de cada arquivo: 4Mb');
              return;
            }
          }

          const promises: Promise<File>[] = [];

          for (let i = 0; i < files.length; i++) {
            if (files[i]) {
              promises.push(compressImage(files[i]));
            }
          }

          const newFiles = await Promise.all(promises);

          newFiles.forEach((fileItem) => {
            data.append('images', fileItem);
          });
          const response = await api.post(
            `/ads-edit/${edit_ad_id}/images/`,
            data
          );

          response.data.forEach(({ id, url }: { id: string; url: string }) => {
            imagesAdded.push({ id, url });
          });
        }

        setImages([...images, ...imagesAdded]);
      } catch (err) {
        toast.error('Falha o upload da imagem');
      } finally {
        setLoading(false);
      }
    },
    [edit_ad_id, images]
  );

  return (
    <Container {...rest}>
      {!loading && images.length > 0 && (
        <Carousel showThumbs={false} showStatus={false}>
          {images.map((view: Preview) => (
            <div className="preview" key={view.id}>
              <div className="overlayIMG">
                <button
                  type="button"
                  onClick={() => removeImg(view.id)}
                  className="button"
                >
                  Excluir
                </button>
              </div>
              <img src={view.url} alt="adPhoto" />
            </div>
          ))}
        </Carousel>
      )}
      {loading ? (
        <div className="loadImage">
          <div>
            <MdRefresh color="#fff" size={36} />
            <strong>Atualizando</strong>
          </div>
        </div>
      ) : (
        <label htmlFor="file">
          {images && images.length === 0 && (
            <div className="imagePicker">
              <div>
                <MdLocalSee color="#fff" size={36} />
                <strong>Selecionar Imagem</strong>
              </div>
            </div>
          )}
          {images && images.length > 0 && (
            <div className="buttonAddImage" data-testid="divWithImage">
              <MdAddCircleOutline size={18} color="#221e35" />
              Adicionar Fotos
            </div>
          )}

          <input
            type="file"
            id="file"
            accept="image/*"
            data-file={value}
            onChange={handleChange}
            multiple
            data-testid="inputFile"
          />
        </label>
      )}
      <input type="hidden" name="file_ids" value={value} ref={ref} />
      {error && <span>{error}</span>}
    </Container>
  );
}

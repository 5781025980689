import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 15px;
  .divButton {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 450px) {
      flex-direction: column;
      a {
        display: flex;
        button {
          flex: 1;
        }
      }
    }
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .loading {
    width: 100%;
    height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      color: #f6f6f6;
      font-size: 24px;
    }
    svg {
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }

  .pageButton {
    margin: 13px;
    height: 44px;
    background: #e73739;
    font-weight: bold;
    min-width: 120px;
    color: #f6f6f6;
    border: 0;
    border-radius: 25px 25px 25px 25px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, '#e73739')};
    }
    @media screen and (max-width: 450px) {
      margin: 20px;
      width: 80%;
    }
  }

  header {
    margin-top: 30px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    h2 {
      color: #f5f5f5;
      text-align: justify;
    }
    h3 {
      font-size: 17px;
      color: #f5f5f5;
      text-align: justify;
      line-height: 1.5em;
    }
    .steps {
      width: 100%;
    }
    ul {
      margin: 30px auto;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    .step {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px 0 0;
      width: 130px;
      height: 44px;
      background: #f5f5f5;
      font-weight: bold;
      color: #221e35;
      border: 0;
      border-radius: 25px 25px 25px 25px;
      font-size: 16px;
    }
    .selected {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px 0 0;
      width: 180px;
      height: 62px;
      background: #f5f5f5;
      font-weight: bold;
      color: #221e35;
      border: 0;
      border-radius: 25px 25px 25px 25px;
      font-size: 20px;
    }
    @media screen and (max-width: 640px) {
      ul {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .carousel {
    margin-top: 20px;
  }
  align-self: center;
  margin: 0 auto 30px;
  width: 100%;
  .slide {
    background-color: rgba(0, 0, 0, 0);
  }
  .preview {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 300px;
      width: auto;
      height: 300px;
      background: #eee;
    }
  }

  .time {
    max-width: 600px;
    margin: 30px auto;
    text-align: center;
    background-color: #f5f5f5;
    border-radius: 10px;
    h3 {
      color: #221e35;
      font-size: 20px;
    }
  }

  .time2 {
    max-width: 600px;
    margin: 30px auto;
    text-align: center;
    /* background-color: #f5f5f5; */
    border-radius: 10px;
    h3 {
      color: #f5f5f5;
      font-size: 20px;
    }
  }

  .description {
    max-width: 600px;
    margin: 30px auto;
    text-align: center;
    h5 {
      color: #f5f5f5;
      font-size: 14px;
    }
  }

  .days {
    ul {
      display: flex;
      justify-content: center;
      margin: 30px;
      li {
        background-color: #221e35;
        color: #f5f5f5;
        margin: 0 15px;
        height: 30px;
        padding: 0 6px;
        text-align: center;
        min-width: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
      }
      @media screen and (max-width: 600px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        li {
          margin: 10px;
        }
      }
    }
  }

  .whatsapp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      font-size: 15px;
      color: #f6f6f6;
    }
    h6 {
      font-size: 18px;
      color: #f6f6f6;
    }
  }

  .categories {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .category {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 60px;
      background-color: #221e35;
      color: #f5f5f5;
      font-size: 20px;
      margin-bottom: 15px;
    }
    .menuItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-height: 60px;
      border-radius: 10px;
      background-color: #f5f5f5;
      color: #221e35;
      font-size: 22px;
      margin: 10px 0;
      @media screen and (max-width: 600px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .descriptionNome {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 12px;

        span {
          margin-top: 5px;
          font-size: 13px;
        }
        div {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
        }
        @media screen and (max-width: 600px) {
          margin: 12px 0;
          justify-content: space-between;
          padding: 0 10px;
        }
      }

      .prices {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin: 0 12px;
        h6 {
          margin: 0 15px;
        }
      }
    }
  }
`;

import React from 'react';
import { AuthProvider } from './auth';
import { SideMenuProvider } from './sideMenu';
import { SignUpContextProvider } from './signUp';

const AppProvider: React.FC = ({ children }) => (
  <SideMenuProvider>
    <SignUpContextProvider>
      <AuthProvider>{children}</AuthProvider>
    </SignUpContextProvider>
  </SideMenuProvider>
);

export default AppProvider;

import React, { createContext, useCallback, useContext, useState } from 'react';

interface SignUpContextData {
  restaurant: Restaurant;
  updateRestaurant: (restaurant: Restaurant) => void;
}

interface Owner {
  name: string;
  cpf: string;
  email: string;
  phone: string;
}

export interface Restaurant {
  razao_social?: string;
  nome_fantasia?: string;
  cnpj?: string;
  email?: string;
  nome_rua?: string;
  phone?: string;
  cidade?: string;
  latitude?: number;
  longitude?: number;
  avatar_url?: string;
  cep?: string;
  estado?: string;
  bairro?: string;
  pais?: string;
  numero?: string;
  owner: Owner;
  password?: string;
  passwordConfirmation?: string;
  oldPassword?: string;
}

const SignUpContext = createContext<SignUpContextData>({} as SignUpContextData);

export const SignUpContextProvider: React.FC = ({ children }) => {
  const [restaurant, setRestaurant] = useState<Restaurant>({} as Restaurant);

  const updateRestaurant = useCallback((newRestaurant: Restaurant) => {
    setRestaurant(newRestaurant);
  }, []);

  return (
    <SignUpContext.Provider value={{ updateRestaurant, restaurant }}>
      {children}
    </SignUpContext.Provider>
  );
};

export const useSignUp = (): SignUpContextData => {
  const context = useContext(SignUpContext);

  if (!context) {
    throw new Error('it should be used within SignUpContext');
  }
  return context;
};

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useRef,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { uuid } from 'uuidv4';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { schemaAddress } from '../schemas/schemas';
import Input from '../../../components/Input';
import api from '../../../services/api';
import { QueryForm, Unform } from './styles';
import { useAuth } from '../../../hooks/auth';

interface Owner {
  name: string;
  cpf: string;
  email: string;
  phone: string;
}

interface PayloadSignUpRequest {
  razao_social?: string;
  nome_fantasia?: string;
  cnpj?: string;
  email?: string;
  nome_rua?: string;
  phone?: string;
  cidade?: string;
  cep?: string;
  estado?: string;
  bairro?: string;
  latitude?: string;
  longitude?: string;
  pais?: string;
  numero?: string;
  especialidade?: string;
  owner?: Owner;
  password?: string;
  passwordConfirmation?: string;
  oldPassword?: string;
}

interface RestaurantAddress {
  nome_rua: string;
  cidade: string;
  bairro: string;
  numero: string;
  estado: string;
  latitude: number;
  longitude: number;
  cep: string;
}

interface Error {
  path: string;
  message: string;
}

interface ValidationError {
  [key: string]: string;
}

interface AutoComplete {
  place_id: string;
  description: string;
}

interface AdressDTO {
  address: {
    street_name: string;
    number: string;
    neighborhood: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
  };
  coordinates: {
    longitude: number;
    latitude: number;
  };
}

export default function FormAddress(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [autoCompleteSearch, setAutoCompleteSearch] = useState('');
  const { restaurant, updateRestaurant } = useAuth();
  const [itemSelected, setItemSelected] = useState(-1);
  const [latitude, setLatitude] = useState(restaurant.latitude);
  const [longitude, setLongitude] = useState(restaurant.longitude);
  const [sugestions, setSugestions] = useState<AutoComplete[]>([]);
  const token = useMemo(() => {
    return uuid();
  }, []);
  const [query, setQuery] = useState('');
  const [result, setResult] = useState(
    `${restaurant.latitude},${restaurant.longitude}`
  );

  useEffect(() => {
    if (autoCompleteSearch) {
      api
        .get('maps-location/autocomplete', {
          params: {
            query: autoCompleteSearch,
            token,
          },
        })
        .then((response) => {
          if (response.data.predictions) {
            setSugestions(response.data.predictions);
          }
        })
        .catch((error) => {
          toast.error('Falha ao buscar os dados, tente novamente');
        });
    }
  }, [autoCompleteSearch, token]);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: RestaurantAddress) => {
      try {
        setLoading(true);
        await schemaAddress.validate(data, {
          abortEarly: false,
        });

        await api.put('restaurants-profile', {
          ...restaurant,
          ...data,
          latitude: latitude.toFixed(6),
          longitude: longitude.toFixed(6),
        });

        updateRestaurant({
          ...restaurant,
          ...data,
          latitude,
          longitude,
        });

        toast.success('Dados atualizados com sucesso');
      } catch (error) {
        const validationErrors: ValidationError = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err: Error) => {
            validationErrors[err.path] = err.message;
          });
          formRef &&
            formRef.current &&
            formRef.current.setErrors(validationErrors);
        } else {
          toast.error('Falha ao atualizar endereço.');
        }
      } finally {
        setLoading(false);
      }
    },
    [restaurant, updateRestaurant, latitude, longitude]
  );

  const searchLocation = useCallback(async (queryParam) => {
    try {
      const response = await api.get(`maps-location?query=${queryParam}`);

      const resultResponse: AdressDTO = response.data;

      if (!resultResponse) {
        toast.error('Busca imprecisa, coloque seu endereço completo');
        return;
      }

      const { latitude: lat, longitude: long } = resultResponse.coordinates;

      const { address } = resultResponse;

      setResult(`${lat},${long}`);

      formRef.current?.setData({
        estado: address.state,
        cidade: address.city,
        numero: address.number,
        bairro: address.neighborhood,
        pais: address.country,
        cep: address.zipCode,
        nome_rua: address.street_name,
      });

      setLatitude(lat);
      setLongitude(long);
    } catch (error) {
      toast.error('Falha ao buscar informações do local. Tente novamente');
    }
  }, []);

  const handleClick = useCallback(async () => {
    if (itemSelected >= 0) {
      setAutoCompleteSearch(sugestions[itemSelected].description);
      setQuery(sugestions[itemSelected].place_id);
      await searchLocation(sugestions[itemSelected].place_id);
      setSugestions([]);
    }
  }, [searchLocation, sugestions, itemSelected]);

  const handleKeyDown = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode === 40 && sugestions.length - 1 > itemSelected) {
        setItemSelected((oldState) => oldState + 1);
      }
      if (e.keyCode === 38 && itemSelected > 0) {
        setItemSelected((oldState) => oldState - 1);
      }
      if (
        e.keyCode === 13 &&
        itemSelected > -1 &&
        itemSelected < sugestions.length
      ) {
        if (itemSelected >= 0) {
          setAutoCompleteSearch(sugestions[itemSelected].description);
          setQuery(sugestions[itemSelected].place_id);
          setSugestions([]);
          searchLocation(sugestions[itemSelected].place_id);
        }
      }
    },
    [sugestions, itemSelected, searchLocation]
  );

  useEffect(() => {
    if (sugestions.length === 0) {
      setItemSelected(-1);
    }
  }, [sugestions]);

  return (
    <>
      <QueryForm>
        <strong>
          Faça uma busca pelo nome do estabelecimento ou pelo endereço
        </strong>
        <input
          value={autoCompleteSearch}
          onChange={(e) => setAutoCompleteSearch(e.target.value)}
          onBlur={handleClick}
          onKeyDown={handleKeyDown}
          data-testid="inputAutoComplete"
        />
        <div>
          <ul>
            {!!sugestions.map &&
              sugestions.map((sugestion, index) => (
                <li
                  className={itemSelected === index ? 'selected' : ''}
                  key={sugestion.place_id}
                >
                  <button
                    type="button"
                    onClick={() => handleClick()}
                    onMouseOver={() => setItemSelected(index)}
                    onFocus={() => setItemSelected(index)}
                  >
                    {sugestion.description}
                  </button>
                </li>
              ))}
          </ul>
        </div>
        <button type="button" onClick={() => searchLocation(query)}>
          {' '}
          Buscar
        </button>
      </QueryForm>
      <iframe
        title="maps"
        width="600"
        height="450"
        frameBorder="0"
        style={{ maxWidth: '90vw', margin: '0 auto' }}
        src={`https://www.google.com/maps/embed/v1/place?q=${result}&key=${process.env.REACT_APP_EMBED_API_KEY}`}
        allowFullScreen
      />
      <Unform
        initialData={restaurant}
        onSubmit={handleSubmit}
        translate="a"
        ref={formRef}
      >
        <span>Por favor, complemente as informações abaixo</span>
        <Input name="cep" className="usual" placeholder="CEP" />
        <Input name="nome_rua" className="usual" placeholder="Nome da rua" />
        <Input name="numero" className="usual" placeholder="Número" />
        <Input name="bairro" className="usual" placeholder="Bairro" />
        <Input name="cidade" className="usual" placeholder="Cidade" />
        <Input name="estado" className="usual" placeholder="Estado" />
        <Input name="pais" className="usual" placeholder="País" />
        <button type="submit">
          {loading ? 'Salvando...' : 'Salvar alterações'}
        </button>
      </Unform>
    </>
  );
}

import styled, { keyframes } from 'styled-components';
import { darken, lighten } from 'polished';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled('div')`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 15px;
  .nomeInput {
    grid-area: 1 / 1 / 1 / 3;
    justify-self: stretch;
  }
  .divButton {
    margin-top: 30px;
  }

  .pageButton {
    margin: 0 auto;
    min-width: 120px;
    justify-content: center;
    height: 44px;
    background: #e73739;
    font-weight: bold;
    color: #f6f6f6;
    border: 0;
    border-radius: 25px 25px 25px 25px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, '#e73739')};
    }
  }
  .loading {
    margin: 10px auto;
    svg {
      animation: ${rotate} 2s linear infinite;
    }
  }

  .help {
    border-radius: 4px;
    padding: 0;
    button {
      background-color: #221e35;
      padding: 5px;
      display: flex;
      align-items: center;
      min-height: 44px;
      border: 0;
      border-radius: 4px;
      padding: 15px;
      margin: 0;
      svg {
        margin: 0;
      }
    }
    display: flex;
    margin: 0 auto;
    background-color: #221e35;
  }
  section {
    header {
      background-color: ${lighten(0.08, '#221e35')};
      margin: 0 0 5px 0;
      padding: 0;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      button {
        margin: 0;
        svg {
          margin: 0;
        }
      }
    }
    p {
      margin: 10px auto;
    }

    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background-color: #fff;
  }
  .addCategory {
    margin: 15px auto;
  }
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  .title {
    background: #f6f6f6;
    border: 0;
    height: 44px;
    padding: 0;
    width: 50%;
    color: #221e35;
    text-align: center;
    margin: 0 auto;
    border-radius: 25px 25px 25px 25px;
    &::placeholder {
      color: #e73739;
    }
  }
  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 28px;
    padding: 0 20px;
    min-height: 44px;
    background: #221e35;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, '#221e35')};
    }
    &:disabled {
      background: ${darken(0.45, '#fff')};
    }
    svg {
      margin-right: 10px;
    }
  }

  header {
    margin-top: 30px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .steps {
      width: 100%;
    }
    h3 {
      font-size: 17px;
      color: #f5f5f5;
      text-align: justify;
      line-height: 1.5em;
    }
    ul {
      margin: 40px auto;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    .step {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px 0 0;
      width: 130px;
      height: 44px;
      background: #f5f5f5;
      font-weight: bold;
      color: #221e35;
      border: 0;
      border-radius: 25px 25px 25px 25px;
      font-size: 14px;
    }

    .selected {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px 0 0;
      width: 180px;
      height: 62px;
      background: #f5f5f5;
      font-weight: bold;
      color: #221e35;
      border: 0;
      border-radius: 25px 25px 25px 25px;
      font-size: 20px;
    }

    @media screen and (max-width: 640px) {
      ul {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    h2 {
      color: #f5f5f5;
      text-align: justify;
    }

    .categoriesTitle {
      margin-top: 15px;
      width: 100%;
      position: relative;
      @media screen and (min-width: 970px) {
        div {
          margin: auto;
          strong {
            font-size: 18px;
          }
        }
        button {
          position: absolute;
          right: 0;
        }
      }
    }
    strong {
      color: #fff;
      font-size: 24px;
      margin: 0 15px 0 0;
    }
    @media screen and (max-width: 970px) {
      .categoriesTitle {
        margin-top: 15px;
        width: 100%;
        justify-content: space-between;
      }
    }

    @media screen and (max-width: 550px) {
      .categoriesTitle {
        .addButton {
          flex-direction: column;
        }
      }
    }
  }
`;

export const Categories = styled('ul')`
  .divItem {
    border-radius: 25px;
    padding: 0 15px;
    div {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      background-color: rgba(0, 0, 0, 0);
      button {
        margin: 0;
      }
    }
  }
  .mobileName {
    display: none;
  }
  .tabletName {
    display: none;
  }
  .desktopName {
    display: flex;
  }
  .mobile {
    display: none;
  }
  display: grid;
  grid-gap: 15px;
  margin-top: 30px;
  li {
    .categoryTitle {
      min-width: 300px;
    }
    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 28px;
      min-height: 44px;
      background: #221e35;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.03, '#221e35')};
      }
      svg {
        margin-right: 5px;
      }
    }
    display: flex;
    justify-content: space-between;
    background: #221e35;
    min-height: 44px;
    align-items: center;
    border-radius: 4px;
    strong {
      color: #fff;
      font-size: 18px;
    }
    div {
      display: flex;
      justify-content: end;
      align-items: center;
      span {
        color: #999;
        font-size: 16px;
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media screen and (max-width: 1115px) {
    .desktopName {
      display: none;
    }
    .tabletName {
      display: flex;
    }
    .mobileName {
      display: none;
    }
    li {
      flex-direction: column;
      background: none;

      div {
        background: #221e35;

        flex: 1;
        width: 100%;
        margin: 2px 0;
        justify-content: center;
        border-radius: 4px;
      }
    }

    .divItem {
      background-color: rgba(0, 0, 0, 0);
      justify-content: center;
    }

    .center {
      align-items: center;
      justify-content: center;
    }
    .desktop {
      display: none;
    }

    .mobile {
      display: flex;
    }
  }

  @media screen and (max-width: 670px) {
    .desktopName {
      display: none;
    }
    .mobileName {
      display: flex;
    }
    .tabletName {
      display: none;
    }

    li {
      flex-direction: column;
      background: none;

      div {
        background: #221e35;

        flex: 1;
        width: 100%;
        margin: 2px 0;
        justify-content: center;
        border-radius: 4px;
      }
    }

    .buttons {
      flex-direction: column;
      display: flex;
      background-color: rgb(0, 0, 0, 0);
      button {
        margin: 2px 0;
        width: 100%;
        justify-content: center;
      }
    }
    .divItem {
      background-color: rgba(0, 0, 0, 0);
      justify-content: center;
    }
    .desktop {
      display: none;
    }
    .mobile {
      display: flex;
    }
    .center {
      align-items: center;
      justify-content: center;
    }
  }
`;

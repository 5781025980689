/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect, useMemo } from 'react';

import { MdEdit, MdDelete } from 'react-icons/md';
import ItemForm from '../ItemForm';

// import { Container } from './styles';
interface Props {
  menuItem: Item;
  handleDelete: (item: Item) => Promise<void>;
}

interface Item {
  name: string;
  id: string;
  description?: string;
  normal_price?: string;
  happy_hour_price?: string;
  category_id: string;
}

export default function Item(props: Props): JSX.Element {
  const [showForm, setShowForm] = useState(false);

  const { menuItem, handleDelete } = props;

  const [item, setItem] = useState<Item>(menuItem);

  const reducedName = useMemo(() => {
    let reduced = item?.name;
    if (item?.name?.length > 50) {
      reduced = `${item.name.substring(0, 49)}...`;
    }
    return reduced;
  }, [item]);

  const update = (info: Item): void => {
    setItem(info);
  };

  useEffect(() => {
    setItem(menuItem);
  }, [menuItem]);

  return (
    <>
      <li>
        <div className="divItem center divTitle">
          <strong>{reducedName}</strong>
        </div>
        <div className="divItem divPrice">
          <span>
            De: {item.normal_price || '-'} {'\u00A0'} Por:{' '}
            {item.happy_hour_price || '-'}
          </span>
          <div className="buttonItems">
            <button
              type="button"
              onClick={(): void => setShowForm(true)}
              data-testid="menuItemShow"
            >
              <MdEdit size={24} color="#999" />
            </button>
            <button
              data-testid="menuItemDelete"
              type="button"
              onClick={(): void => {
                if (window.confirm('Deseja Realmente excluir este item?')) {
                  handleDelete(item);
                }
              }}
            >
              <MdDelete size={24} color="#999" />
            </button>
          </div>
        </div>
      </li>
      {showForm && (
        <ItemForm
          data={item}
          edit
          handleCancel={(): void => setShowForm(false)}
          addHandle={update}
        />
      )}
    </>
  );
}

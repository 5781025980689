import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .carousel {
    margin-top: 20px;
  }
  align-self: center;
  margin: 0 auto 30px;
  width: 80%;
  .preview {
    button {
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 4px;
      margin-left: 28px;
      padding: 0 17px;
      width: 80px;
      height: 35px;
      background: #e73739;
      color: #fff;
      border: 0;
      text-align: center;
      border-radius: 20px;
      font-size: 14px;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.03, '#e73739')};
      }
      svg {
        margin-right: 5px;
      }
    }
    .overlayIMG {
      padding: 3px 0;
      background: rgba(0, 0, 0, 0.05);
      height: 50px;
    }
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 300px;
      width: auto;
      height: 300px;
      background: #eee;
    }
  }
  .loadImage {
    background: #19141f;
    height: 300px;
    width: 100%;
    div {
      svg {
        animation-name: spin;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      strong {
        color: #fff;
        size: 36px;
      }
    }
  }
  label {
    display: block;
    margin: 10px 0;

    .button {
      padding: 0;
      display: flex;
      max-width: 250px;
      justify-content: center;
      align-items: center;
      margin-left: 28px;
      padding: 0 20px;
      height: 44px;
      background: #e73739;
      color: #fff;
      border: 0;
      border-radius: 20px;
      font-size: 16px;
      transition: background 0.2s;
      margin: 0 auto;
      &:hover {
        background: ${darken(0.03, '#e73739')};
      }
      svg {
        margin-right: 5px;
      }
    }

    .buttonAddImage {
      padding: 0;
      display: flex;
      max-width: 180px;
      justify-content: center;
      align-items: center;
      margin-left: 28px;
      padding: 0 20px;
      height: 40px;
      background: #fff;
      color: #221e35;
      border: 0;
      border-radius: 20px;
      font-size: 14px;
      transition: background 0.2s;
      margin: 0 auto;
      &:hover {
        background: ${darken(0.03, '#fff')};
      }
      svg {
        margin-right: 5px;
      }
    }

    .imagePicker {
      background: #19141f;
      height: 300px;
      width: 100%;
      div {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        strong {
          color: #fff;
          size: 36px;
        }
      }
    }
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    input {
      display: none;
    }
  }
`;

import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Comfortaa&display=swap');
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  .Toastify__toast {
    font-family: 'Comfortaa', sans-serif;
  }
  .Toastify__toast--success {
    background: #f6f6f6; 
    color: #221e35;
  }
  .Toastify__toast--error {
    background: #221e35; 
    color: #f6f6f6;
  }
  #root{
    flex: 1;
  }
  body{
    min-height: 100%;
    display: flex;
  }

  html {
    height:100%;
    min-height: 100%;
  }
  body {
    -webkit-font-smoothing: antialiased;
  }
  body, input, button, select {
    font: 14px 'Comfortaa', sans-serif;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
  }
  input, select, button {
    border-radius: 0;
  }
`;

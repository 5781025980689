import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import Hamburguer from './components/Hamburguer';

import logo from '../../assets/logotipo.svg';

import IconRestaurant from '../../assets/iconRestaurant.png';

import { useAuth } from '../../hooks/auth';

import { Container, Content, Profile } from './styles';

export default function Header(): JSX.Element {
  const { signOut, restaurant } = useAuth();

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);
  return (
    <Container>
      <Content className="content">
        <img src={logo} alt="appyHour" className="desktop" />

        <Hamburguer className="mobile" />

        <aside className="desktop">
          <Profile>
            <div>
              <strong>{restaurant ? restaurant.nome_fantasia : ''}</strong>
              <Link to="/profile">Meu perfil</Link>
            </div>
            <Link to="/profile">
              <img
                src={restaurant?.avatar_url || IconRestaurant}
                alt="profile"
              />
            </Link>
            <button type="button" onClick={handleSignOut}>
              Sair
            </button>
          </Profile>
        </aside>
      </Content>
    </Container>
  );
}

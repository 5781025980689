import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUpInfo from '../pages/SignUp/pages/FormInfo';
import SignUpAddress from '../pages/SignUp/pages/FormAddress';
import SignUpOwner from '../pages/SignUp/pages/FormOwner';
import SignUpChecks from '../pages/SignUp/pages/FormChecks';
import ForgotPassword from '../pages/ForgotPassword';
import ChangePassword from '../pages/ChangePassword';
import ChangePasswordUser from '../pages/ChangePasswordUser';
import Dashboard from '../pages/Dashboard';
import FileAd from '../pages/FileAd';
import DataAd from '../pages/DataAd';
import AdViewer from '../pages/AdViewer';
import Profile from '../pages/Profile';
import Comments from '../pages/Comments';

export default function Routes(): JSX.Element {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/register" component={SignUpInfo} />
      <Route path="/register-address" component={SignUpAddress} />
      <Route path="/register-owner" component={SignUpOwner} />
      <Route path="/register-checks" component={SignUpChecks} />
      <Route path="/check_token/:token" component={ChangePassword} />
      <Route
        path="/reset_password_user/:token"
        component={ChangePasswordUser}
      />
      <Route path="/forgot_password" component={ForgotPassword} />
      <Route path="/comments" component={Comments} isPrivate />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/FileAd" component={FileAd} isPrivate />
      <Route path="/DataAd" component={DataAd} isPrivate />
      <Route path="/AdViewer" component={AdViewer} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />
    </Switch>
  );
}

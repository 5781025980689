import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

interface Props<T> {
  name: string;
  value: string;
}
type InputProps = JSX.IntrinsicElements['input'] & Props<false>;
export default function Input({
  name,
  value,
  ...rest
}: InputProps): JSX.Element {
  const ref = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const { fieldName, defaultValue = '', registerField } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const props = {
    ...rest,
    ref,
    id: fieldName,
    name: fieldName,
    'aria-label': fieldName,
    defaultValue,
  };
  return (
    <>
      <input {...(props as InputProps)} type="hidden" value={value} />
    </>
  );
}

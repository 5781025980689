import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import Input from '../../components/Input';
import InputPassword from '../../components/PasswordInput';
import logo from '../../assets/logotipo.png';
import { useAuth } from '../../hooks/auth';

// import { Container } from './styles';

interface DataContent {
  email: string;
  password: string;
}

export default function SignIn(): JSX.Element {
  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async (data: DataContent) => {
      try {
        await signIn(data);
      } catch (err) {
        toast.error('Falha no login');
      }
    },
    [signIn]
  );

  return (
    <>
      <img src={logo} width="250" alt="appy-hour" />

      <Form onSubmit={handleSubmit} translate="no">
        <Input
          type="email"
          className="usual"
          placeholder="Seu e-mail"
          name="email"
        />
        <InputPassword
          className="usual"
          name="password"
          placeholder="Sua senha"
        />

        <button type="submit">Acessar</button>
        <Link to="/register">Criar conta</Link>
        <Link to="/forgot_password">Esqueceu a Senha?</Link>
      </Form>
    </>
  );
}

/* eslint-disable @typescript-eslint/camelcase */
import * as Yup from 'yup';

interface Item {
  name: string;
  normal_price?: string;
  happy_hour_price?: string;
  description?: string;
}
// };

function test(this: Yup.TestContext, value: any): boolean {
  if (!this.parent.normal_price || !value) {
    return true;
  }
  const normal_price = parseFloat(
    (this.parent.normal_price as string)
      .substr(2)
      .trim()
      .replace(/\./g, '')
      .replace(/,/g, '.')
  );

  const happy_hour_price = parseFloat(
    (value as string).substr(2).trim().replace(/\./g, '').replace(/,/g, '.')
  );

  return happy_hour_price < normal_price;
}

export const itemSchema = Yup.object().shape<Item>({
  name: Yup.string()
    .required('Nome é obrigatório')
    .max(50, 'Tamanho máximo: 50 caracteres'),
  normal_price: Yup.string(),
  happy_hour_price: Yup.string().test(
    'less-than-normal',
    'Deve ser menor que o preço normal',
    test
  ),
  description: Yup.string().max(100),
});

/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useMemo } from 'react';
import { toast } from 'react-toastify';

import {
  MdEdit,
  MdKeyboardArrowDown,
  MdAddCircleOutline,
  MdKeyboardArrowUp,
  MdDelete,
} from 'react-icons/md';

import { Items } from './styles';
import CategoryForm from '../CategoryForm';
import api from '../../../../services/api';
import Item from '../Item';
import ItemForm from '../ItemForm';

interface Props {
  data: Category;
  handleDelete: (category: Category) => Promise<void>;
  loadHandle: () => Promise<void>;
}

interface Category {
  name: string;
  id: string;
  menuItens: ItemMenu[];
}

interface ItemMenu {
  name: string;
  id: string;
  description?: string;
  normal_price?: string;
  happy_hour_price?: string;
  category_id: string;
}

const MenuItem: React.FC<Props> = (props: Props) => {
  const [showForm, setShowForm] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const [showAddItem, setShowAddItem] = useState(false);
  const { data, handleDelete, loadHandle } = props;
  const [items, setItems] = useState<ItemMenu[]>(data.menuItens);
  async function loadData(): Promise<void> {
    try {
      const response = await api.get(
        `/categories-edit/${data.id}/menu-itens-edit`
      );
      setItems(response.data);
    } catch (err) {
      toast.error('Falha ao atualizar');
    }
  }

  const [category, setCategory] = useState(data);

  const reducedName = useMemo<string>(() => {
    let reduced = category.name;
    if (category?.name?.length > 50) {
      reduced = `${category.name.substring(0, 49)}...`;
    }
    return reduced;
  }, [category]);

  const update = (info: Category): void => {
    setCategory(info);
  };

  const addItem = (): void => {
    loadData();
    loadHandle();
    setShowItems(true);
  };

  const deleteItem = async (item: ItemMenu): Promise<void> => {
    try {
      await api.delete(`categories-edit/${data.id}/menu-itens-edit/${item.id}`);
    } catch {
      toast.error('Falha ao deletar o item');
    }
    loadData();
    loadHandle();
  };

  return (
    <>
      <li>
        <div className="categoryTitle">
          <button
            onClick={(): void => setShowForm(true)}
            type="button"
            data-testid="editCategory"
          >
            <MdEdit size={24} color="#fff" />
          </button>
          <strong className="desktopName">{reducedName}</strong>
          <strong className="mobileName">{reducedName}</strong>
          <strong className="tabletName">{category.name}</strong>
        </div>
        <div className="buttons">
          <button
            type="button"
            onClick={(): void => setShowItems(!showItems)}
            className="desktop"
          >
            {showItems ? (
              <>
                <MdKeyboardArrowUp size={24} color="#FFF" />
                Esconder Itens
              </>
            ) : (
              <>
                <MdKeyboardArrowDown size={24} color="#FFF" />
                Ver Itens
              </>
            )}
          </button>

          <button type="button" onClick={(): void => setShowAddItem(true)}>
            <MdAddCircleOutline size={24} color="#FFF" />
            Adicionar Item
          </button>
          <button
            type="button"
            onClick={(): void => {
              if (window.confirm('Deseja realmente excluir esta categoria?')) {
                handleDelete(category);
              }
            }}
          >
            <MdDelete size={24} color="#FFF" />
            Excluir Categoria
          </button>

          <button
            type="button"
            onClick={(): void => setShowItems(!showItems)}
            className="mobile"
          >
            {showItems ? (
              <>
                <MdKeyboardArrowUp size={24} color="#FFF" />
                Esconder Itens
              </>
            ) : (
              <>
                <MdKeyboardArrowDown size={24} color="#FFF" />
                Itens
              </>
            )}
          </button>
        </div>
      </li>

      {showForm && (
        <CategoryForm
          data={category}
          edit
          handleCancel={(): void => {
            setShowForm(false);
          }}
          addHandle={update}
        />
      )}
      {showAddItem && (
        <ItemForm
          handleCancel={(): void => setShowAddItem(false)}
          edit={false}
          addHandle={addItem}
          data={{
            name: '',
            normal_price: '',
            happy_hour_price: '',
            category_id: data.id,
            id: '',
          }}
        />
      )}
      {showItems && (
        <Items>
          {items.map((menuItem) => (
            <Item
              menuItem={menuItem}
              key={`${menuItem.id}`}
              handleDelete={deleteItem}
            />
          ))}
        </Items>
      )}
    </>
  );
};

export default MenuItem;

/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useRef } from 'react';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import CurrencyFormat from 'react-currency-format';
import { toast } from 'react-toastify';
import Input from '../../../../components/Input';

import api from '../../../../services/api';

import { Container } from './styles';

import { itemSchema } from './schemas/schema';

interface Props {
  data: Item;
  edit: boolean;
  handleCancel: () => void;
  addHandle: (info: Item) => void;
}

interface Item {
  name: string;
  id: string;
  description?: string;
  category_id: string;
  normal_price?: string;
  happy_hour_price?: string;
}

interface Error {
  path: string;
  message: string;
}

interface ValidationError {
  [key: string]: string;
}

const FormItem: React.FC<Props> = (props: Props) => {
  const formRef = useRef<FormHandles>(null);
  const { data, edit, handleCancel, addHandle } = props;
  const [nameLength, setNameLength] = useState(50);
  const [descriptionLength, setDescriptionLength] = useState(100);
  const [normalPrice, setNormalPrice] = useState(data.normal_price);
  const [happyHourPrice, setHappyHourPrice] = useState(data.happy_hour_price);

  const handleSubmit: SubmitHandler<Item> = async (info) => {
    try {
      await itemSchema.validate(info, {
        abortEarly: false,
      });

      let response;
      if (edit) {
        response = await api.put(
          `/categories-edit/${data.category_id}/menu-itens-edit/${data.id}`,
          info
        );
      } else {
        response = await api.post(
          `/categories-edit/${data.category_id}/menu-itens-edit`,
          info
        );
      }

      addHandle(response.data);

      handleCancel();
    } catch (error) {
      const validationErrors: ValidationError = {};
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((err: Error) => {
          validationErrors[err.path] = err.message;
        });
        // eslint-disable-next-line no-unused-expressions
        formRef &&
          formRef.current &&
          formRef.current.setErrors(validationErrors);
        return;
      }
      toast.error('Falha ao tentar fazer o update');
    }
  };
  return (
    <Container>
      <Form
        initialData={data}
        onSubmit={handleSubmit}
        translate="a"
        ref={formRef}
      >
        <Input
          name="name"
          placeholder="Nome*"
          maxLength={50}
          onChange={() => {
            const name =
              (formRef.current?.getFieldValue('name') as string) || '';
            setNameLength(50 - name.length);
          }}
        />
        <span>
          {`${nameLength} `}
          caracteres restantes
        </span>
        <Input
          name="description"
          placeholder="Descrição (Opcional)"
          maxLength={100}
          onChange={() => {
            const name =
              (formRef.current?.getFieldValue('description') as string) || '';
            setDescriptionLength(100 - name.length);
          }}
        />
        <span>
          {`${descriptionLength} `}
          caracteres restantes
        </span>
        <CurrencyFormat
          value={normalPrice}
          onValueChange={(values): void => {
            const { formattedValue } = values;
            setNormalPrice(formattedValue);
          }}
          customInput={Input}
          name="normal_price"
          placeholder="Preço Normal"
          decimalScale={2}
          fixedDecimalScale
          displayType="input"
          thousandSeparator="."
          prefix="R$"
          decimalSeparator=","
        />
        <CurrencyFormat
          value={happyHourPrice}
          onValueChange={(values): void => {
            const { formattedValue } = values;
            setHappyHourPrice(formattedValue);
          }}
          customInput={Input}
          name="happy_hour_price"
          placeholder="Preço Happy Hour"
          decimalScale={2}
          fixedDecimalScale
          displayType="input"
          thousandSeparator="."
          prefix="R$"
          decimalSeparator=","
        />

        <div>
          <button type="submit">Salvar</button>
          <button type="button" onClick={handleCancel}>
            Cancelar
          </button>
        </div>
      </Form>
    </Container>
  );
};

export default FormItem;

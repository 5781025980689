import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  button {
    background: #f6f6f6;
    width: 240px;
    border-radius: 20px;
    height: 44px;
    border-width: 0;
    transition: background-color 0.4s;
    &:hover {
      background: ${darken(0.06, '#f6f6f6')};
    }
  }

  @media screen and (max-width: 1030px) {
    flex-direction: column;
    align-items: center;
    button + button {
      margin-top: 15px;
    }
  }
`;

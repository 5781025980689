/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useCallback, ChangeEvent, useState } from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { cnpj } from 'cpf-cnpj-validator';
import { toast } from 'react-toastify';
import { FiCamera } from 'react-icons/fi';
import { Unform, AvatarInput } from './styles';
import IconRestaurant from '../../../assets/iconRestaurant.png';
import Input from '../../../components/Input';
import InputMask from '../../../components/InputMask';
import PhoneInput from '../../../components/PhoneInput';
import { schemaInfo } from '../schemas/schemas';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';
import ValidationTypeError from '../../../errors/ValidationTypeError';

interface Error {
  path: string;
  message: string;
}

interface ValidationError {
  [key: string]: string;
}

interface RestaurantInfo {
  razao_social: string;
  nome_fantasia: string;
  cnpj: string;
  phone: string;
  especialidade: string;
  password: string;
  passwordConfirmation: string;
  email: string;
}

export default function FormInfo(): JSX.Element {
  const { restaurant, updateRestaurant } = useAuth();

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [loading, setLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: RestaurantInfo) => {
      try {
        setLoadingSubmit(true);
        // eslint-disable-next-line no-unused-expressions
        formRef && formRef.current && formRef.current.setErrors({});
        await schemaInfo.validate(data, {
          abortEarly: false,
        });

        if (!cnpj.isValid(data.cnpj)) {
          throw new ValidationTypeError('cnpj');
        }

        // eslint-disable-next-line no-unused-expressions
        await api.put('restaurants-profile', { ...restaurant, ...data });

        updateRestaurant({ ...restaurant, ...data });

        toast.success('Dados atualizados com sucesso');
      } catch (error) {
        const validationErrors: ValidationError = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err: Error) => {
            validationErrors[err.path] = err.message;
          });
          // eslint-disable-next-line no-unused-expressions
          formRef &&
            formRef.current &&
            formRef.current.setErrors(validationErrors);
          return;
        }
        if (error instanceof ValidationTypeError) {
          validationErrors[error.field] = error.message;
          // eslint-disable-next-line no-unused-expressions
          formRef &&
            formRef.current &&
            formRef.current.setErrors(validationErrors);
        }
        toast.error('Falha ao atualizar dados');
      } finally {
        setLoadingSubmit(false);
      }
    },
    [restaurant, updateRestaurant]
  );

  const handleAvatarChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        try {
          setLoading(true);
          const data = new FormData();

          data.append('avatar', e.target.files[0]);

          const response = await api.patch('restaurants/', data);

          updateRestaurant(response.data);

          toast.success('Avatar atualizado com sucesso');
        } catch (err) {
          toast.error('Falha ao atualizar avatar');
        } finally {
          setLoading(false);
        }
      }
    },
    [updateRestaurant]
  );

  return (
    <>
      <AvatarInput>
        {loading ? (
          <div className="loader" data-testid="loader" />
        ) : (
          <>
            <img
              src={restaurant.avatar_url || IconRestaurant}
              alt={restaurant.nome_fantasia}
              data-testid="img"
            />
            <label htmlFor="avatar">
              <FiCamera />
              <input
                type="file"
                id="avatar"
                onChange={handleAvatarChange}
                data-testid="inputFile"
              />
            </label>
          </>
        )}
      </AvatarInput>
      <Unform
        initialData={restaurant}
        onSubmit={handleSubmit}
        translate="a"
        ref={formRef}
      >
        <Input
          label="Razão Social*"
          className="usual"
          name="razao_social"
          placeholder="Razão Social*"
        />
        <Input
          className="usual"
          name="nome_fantasia"
          placeholder="Nome Fantasia*"
          label="Nome Fantasia*"
        />
        <InputMask
          name="cnpj"
          className="usual"
          mask="99.999.999/9999-99"
          placeholder="CNPJ*"
          label="CNPJ*"
        />
        <Input
          className="usual"
          name="email"
          placeholder="E-mail*"
          label="E-mail*"
          type="email"
        />
        <PhoneInput
          name="phone"
          placeholder="Telefone*"
          className="usual"
          label="Telefone*"
        />
        <Input
          className="usual"
          name="password"
          type="password"
          placeholder="Nova Senha"
          label="Nova Senha"
          autoComplete="new-password"
        />
        <Input
          name="passwordConfirmation"
          className="usual"
          type="password"
          placeholder="Confirmação de nova Senha"
          label="Confirmação de nova Senha"
          autoComplete="new-password"
        />

        <Input
          name="old_password"
          className="usual"
          type="password"
          placeholder="Senha antiga"
          label="Senha antiga"
          autoComplete="new-password"
        />
        <br />

        <button type="submit">
          {loadingSubmit ? 'Salvando...' : 'Salvar alterações'}
        </button>
      </Unform>
    </>
  );
}

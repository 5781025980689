import React, { useEffect, useRef, RefObject } from 'react';
import { useField } from '@unform/core';
import InputMask from 'react-input-mask';

interface Props {
  name1: string;
  mask: string;
  name2: string;
  label?: string;
  containerProps?: JSX.IntrinsicElements['div'];
}

interface InputElement {
  setInputValue: Function;
}
type InputProps = JSX.IntrinsicElements['input'] & Props;
export default function Input({
  name1,
  mask,
  name2,
  containerProps,
  ...rest
}: InputProps): JSX.Element {
  const ref1 = useRef<InputMask>(null);
  const ref2 = useRef<InputMask>(null);
  const {
    fieldName: fieldName1,
    defaultValue: defaultValue1,
    registerField: registerField1,
    error: error1,
  } = useField(name1);
  const {
    fieldName: fieldName2,
    defaultValue: defaultValue2,
    registerField: registerField2,
    error: error2,
  } = useField(name2);

  const setValue = (ref: InputElement, value: string): void => {
    ref.setInputValue(value);
  };

  useEffect(() => {
    registerField1({
      name: fieldName1,
      ref: ((ref1 as unknown) as RefObject<HTMLInputElement>)
        .current as HTMLInputElement,
      path: 'value',
      setValue,
    });
      }, [ref1.current, fieldName1]); // eslint-disable-line

  useEffect(() => {
    registerField2({
      name: fieldName2,
      ref: ((ref2 as unknown) as RefObject<HTMLInputElement>)
        .current as HTMLInputElement,
      path: 'value',
      setValue,
    });
      },  [ref2.current, fieldName2]); // eslint-disable-line

  const props1 = {
    ...rest,
    mask,
    ref: ref1,
    id: fieldName1,
    name: fieldName1,
    'aria-label': fieldName1,
    defaultValue: defaultValue1,
  };

  const props2 = {
    ...rest,
    mask,
    ref: ref2,
    id: fieldName2,
    name: fieldName2,
    'aria-label': fieldName2,
    defaultValue: defaultValue2,
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: 0,
          padding: 0,
        }}
        {...containerProps}
      >
        <div>
          <InputMask {...props1} />
          às
          <InputMask {...props2} />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: 0,
            padding: 0,
          }}
        >
          {error1 && (
            <span>
              Horário Inicial:
              {error1}
            </span>
          )}
          {error2 && (
            <span>
              Horário Final:
              {error2}
            </span>
          )}
        </div>
      </div>
    </>
  );
}

import styled from 'styled-components';
import { Form } from '@unform/web';
import { darken, shade } from 'polished';

export const AvatarInput = styled.div`
  margin-top: 30px;
  img {
    width: 186px;
    height: 186px;
    border-radius: 50%;
  }
  .loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  label {
    position: absolute;
    width: 48px;
    height: 48px;
    background: #221e35;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    transition: background-color 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
      color: #f6f6f6;
    }
    &:hover {
      background: ${shade(0.2, '#221e35')};
    }
    input {
      display: none;
    }
  }
  position: relative;
  align-self: center;
  margin-bottom: 32px;
`;

export const Unform = styled(Form)`
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 30px auto;

  span {
    color: #f6f6f6;
    margin-bottom: 5px;
  }

  select {
    background: #f6f6f6;
    border: 0;
    height: 44px;
    padding: 0 15px;
    margin: 0 0 10px;
    border-radius: 0 25px 0 25px;
    color: #e73739;
  }

  select.selected {
    color: #221e35;
  }

  input.usual {
    height: 44px;
  }
  input {
    background: #f6f6f6;
    border: 0;
    padding: 0 15px;
    color: #221e35;
    margin: 0 0 10px;
    border-radius: 25px 25px 25px 25px;
    &::placeholder {
      color: #e73739;
    }
  }
  label {
    color: #f6f6f6;
    margin-bottom: 5px;
  }
  button {
    /* Foram adicionados os atributos width e margin para diminuir o tamanho
    do botão acessar na página de login*/
    margin: auto;
    /* margin: 5px 0 0;*/
    height: 44px;
    width: 100%;
    max-width: 240px;
    background: #e73739;
    font-weight: bold;
    color: #f6f6f6;
    border: 0;
    border-radius: 25px 25px 25px 25px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, '#e73739')};
    }
  }
  a {
    color: #fff;
    margin-top: 15px;
    font-size: 16px;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
`;

import * as Yup from 'yup';

interface AdType {
  message: string;
  comment_id: string;
  answer_id?: string;
}
const schema = Yup.object().shape<AdType>({
  message: Yup.string()
    .required('Mensagem de resposta é obrigatório')
    .max(100, 'Máximo de 100 caracteres'),
  comment_id: Yup.string().required(),
  answer_id: Yup.string(),
});

export default schema;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useMemo, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useHistory, Link } from 'react-router-dom';
import { useSignUp } from '../../../../hooks/signUp';
import logo from '../../../../assets/logotipo.png';
import api from '../../../../services/api';

export default function FormCheck(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const classOne = 'form-progress-indicator one';
  const classTwo = 'form-progress-indicator two';
  const classThree = 'form-progress-indicator three';
  const classFour = 'form-progress-indicator four active';
  const history = useHistory();
  const { restaurant } = useSignUp();
  const [useTerms, setUseTerms] = useState(false);
  const [privacyTerms, setPrivacyTerms] = useState(false);

  const enableSubmit = useMemo(
    () => useTerms && privacyTerms,
    [useTerms, privacyTerms]
  );

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      await api.post('/restaurants', restaurant);
      toast.success('Cadastro realizado com sucesso');
      history.push('/');
    } catch (err) {
      if (err.response?.status === 400) {
        toast.error(err.response.data.message);
        return;
      }
      toast.error('Falha no cadastro');
    } finally {
      setLoading(false);
    }
  }, [restaurant, history]);

  return (
    <>
      <img src={logo} width="250" alt="appy-hour" />

      <div className="form-progress">
        <progress className="form-progress-bar" max="100" value={100} />

        <div className={classOne} />
        <div className={classTwo} />
        <div className={classThree} />
        <div className={classFour} />
      </div>
      <form>
        <span>Clique nos termos para ler</span>
        <div className="checks">
          <button
            className="link"
            type="button"
            onClick={() =>
              window.open('https://appyhour.com.br/plataforma.html', '_blank')
            }
          >
            Aceito os Termos de Uso
          </button>
          <input
            className="terms"
            type="checkbox"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              setUseTerms(e.target.checked)
            }
            data-testid="choiceUse"
          />
        </div>

        <div className="checks">
          <button
            className="link"
            type="button"
            onClick={() =>
              window.open('https://appyhour.com.br/plataforma.html', '_blank')
            }
          >
            Aceito os Termos de Privacidade
          </button>
          <input
            className="terms"
            type="checkbox"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              setPrivacyTerms(e.target.checked)
            }
            data-testid="choicePrivacy"
          />
        </div>

        <div className="divButtonPage">
          <button
            type="button"
            onClick={(): void => history.push('register-owner')}
          >
            Voltar
          </button>
          <button
            type="button"
            onClick={handleSubmit}
            disabled={!enableSubmit || loading}
          >
            {loading ? 'Criando...' : 'Criar'}
          </button>
        </div>
        <span>
          Para continuar, é necessário aceitar os termos de nossos serviços
        </span>
      </form>
      <Link to="/">Já tem conta?</Link>
      <br />
      <br />
      <br />
      <br />
    </>
  );
}

import React from 'react';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import GlobalStyle from './styles/global';

import Routes from './routes';
import history from './services/history';
import AppProvider from './hooks';

const App: React.FC = () => (
  <AppProvider>
    <Router history={history}>
      <Routes />
      <GlobalStyle />
      <ToastContainer autoClose={3000} />
    </Router>
  </AppProvider>
);

export default App;

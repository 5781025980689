import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';
import icon_cardapio_plataforma from '../../assets/icons/icon_cardapio_plataforma.png';
import icon_comentarios from '../../assets/icons/icon_comentarios.png';
// import icon_pagamento from '../../assets/icons/icon_pagamento.png';
import icon_ajuda from '../../assets/icons/icon_ajuda.png';
import icon_foward from '../../assets/icons/icon_foward.png';
import icon_back from '../../assets/icons/icon_back.png';

import { useSideMenu } from '../../hooks/sideMenu';

export default function Side(): JSX.Element {
  const { expanded, updateExpanded } = useSideMenu();
  return (
    <Container className={expanded ? 'long' : 'short'}>
      <ul>
        <li>
          {' '}
          <Link to="/Dashboard">
            <button type="button">
              <div
                className={expanded ? 'longDiv' : 'shortDiv'}
                data-testid="classDiv"
              >
                <img
                  src={icon_cardapio_plataforma}
                  alt="cardapio"
                  width="30px"
                />
                {expanded && <span>Cardápio</span>}
              </div>
            </button>
          </Link>
        </li>
        <li>
          <Link to="/comments">
            <button type="button">
              <div
                className={expanded ? 'longDiv' : 'shortDiv'}
                data-testid="classDiv"
              >
                <img src={icon_comentarios} alt="comentarios" width="30px" />
                {expanded && <span>Comentários</span>}
              </div>
            </button>
          </Link>
        </li>
        {/* <li>
          <button
            type="button"
            onClick={() =>
              alert(
                'Estamos em construção. Em breve teremos mais esta opção para te atender'
              )
            }
          >
            <div className={expanded ? 'longDiv' : 'shortDiv'}>
              <img
                src={icon_pagamento}
                alt="pagamentos"
                width="27px"
                height="36px"
              />
              {expanded && <span>Pagamentos</span>}
            </div>
          </button>
        </li> */}
        <li>
          <button
            type="button"
            onClick={() =>
              alert(
                'Estamos em construção. Em breve teremos mais esta opção para te atender'
              )
            }
          >
            <div className={expanded ? 'longDiv' : 'shortDiv'}>
              <img src={icon_ajuda} alt="ajuda" width="24px" height="31px" />
              {expanded && <span>Ajuda</span>}
            </div>
          </button>
        </li>
      </ul>
      <button
        type="button"
        onClick={(): void => updateExpanded(!expanded)}
        className={expanded ? 'backButton moreRight' : 'backButton moreLeft'}
        data-testid="buttonSideMenu"
      >
        {expanded ? (
          <img src={icon_back} alt="back" height="20px" />
        ) : (
          <img src={icon_foward} alt="forwardIcon" height="20px" />
        )}
      </button>
    </Container>
  );
}

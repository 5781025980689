import React, { useRef, useState } from 'react';
import { SubmitHandler, FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import Input from '../../../../components/Input';
import api from '../../../../services/api';

import { Container } from './styles';
import { categorySchema } from './schemas/schema';

interface Props {
  data: Category;
  edit: boolean;
  handleCancel: () => void;
  addHandle: (info: Category) => void;
}

interface Category {
  name: string;
  id: string;
  menuItens: ItemMenu[];
}

interface ItemMenu {
  name: string;
  id: string;
  description?: string;
  normal_price?: string;
  happy_hour_price?: string;
  category_id: string;
}

interface Error {
  path: string;
  message: string;
}

interface ValidationError {
  [key: string]: string;
}

const FormItem: React.FC<Props> = (props: Props) => {
  const formRef = useRef<FormHandles>(null);
  const { data, edit, handleCancel, addHandle } = props;
  const [nameLength, setNameLength] = useState(50);

  const handleSubmit: SubmitHandler<Category> = async (info) => {
    try {
      await categorySchema.validate(info, {
        abortEarly: false,
      });
      let response;
      if (edit) {
        response = await api.put(`/categories-edit/${data.id}`, info);
      } else {
        response = await api.post('/categories-edit', info);
      }

      addHandle(response.data);

      handleCancel();
    } catch (error) {
      const validationErrors: ValidationError = {};
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((err: Error) => {
          validationErrors[err.path] = err.message;
        });
        // eslint-disable-next-line no-unused-expressions
        formRef &&
          formRef.current &&
          formRef.current.setErrors(validationErrors);
        return;
      }
      toast.error('Falha ao salvar categoria. Verifique o nome ou a conexão');
    }
  };
  return (
    <Container>
      <Form
        initialData={data}
        onSubmit={handleSubmit}
        translate="a"
        ref={formRef}
      >
        <Input
          name="name"
          placeholder="Ex: Bebidas, Sobremesas, Petiscos, Massas*"
          maxLength={50}
          onChange={() => {
            const name =
              (formRef.current?.getFieldValue('name') as string) || '';
            setNameLength(50 - name.length);
          }}
        />
        <span>
          {`${nameLength} `}
          caracteres restantes
        </span>
        <div>
          <button type="submit">Salvar</button>
          <button type="button" onClick={handleCancel}>
            Cancelar
          </button>
        </div>
      </Form>
    </Container>
  );
};

export default FormItem;

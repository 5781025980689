import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  background: #221e35;
  padding: 0;
  .mobile {
    display: none;
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;

  @media screen and (max-width: 870px) {
    .mobile {
      display: flex;
    }

    .desktop {
      display: none;
    }
    width: 100%;
  }
`;

export const Content = styled.div`
  height: 64px;

  max-width: 100vw;
  margin: 0;
  margin-right: 40px;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    height: 30px;
  }
  nav {
    display: flex;
    align-items: center;
    ul {
      list-style: none;
      overflow: hidden;

      li {
        float: left;
        margin: 0 20px;
      }
    }
    a {
      display: block;
      margin-top: 2px;
      font-size: 16px;
      color: #f6f6f6;
    }
  }
  aside {
    display: flex;
    align-items: center;
  }
`;

export const Profile = styled.div`
  display: flex;
  margin-left: 20px;
  padding-left: 20px;
  align-items: center;
  img {
    border-radius: 50%;
    height: 45px;
    padding: 0;
    width: 45px;
  }
  div {
    text-align: right;
    margin-right: 10px;
    strong {
      display: block;
      color: #f6f6f6;
    }
    a {
      display: block;
      margin-top: 2px;
      font-size: 12px;
      color: #999;
      text-decoration: underline;
    }
  }
  button {
    margin-left: 18px;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 0 20px;
    height: 30px;
    /* height: 44px; */
    background: #e73739;
    color: #f6f6f6;
    border: 0;
    border-radius: 4px;
    font-size: 14px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, '#e73739')};
    }
  }
`;

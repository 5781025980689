import React, { createContext, useState, useCallback, useContext } from 'react';

interface SideMenuData {
  expanded: boolean;
  updateExpanded(shouldExpand: boolean): void;
}

const SideMenuContext = createContext<SideMenuData>({} as SideMenuData);

export const SideMenuProvider: React.FC = ({ children }) => {
  const [expanded, setExpanded] = useState(true);
  const updateExpanded = useCallback((expand: boolean) => {
    setExpanded(expand);
  }, []);

  return (
    <SideMenuContext.Provider value={{ expanded, updateExpanded }}>
      {children}
    </SideMenuContext.Provider>
  );
};

export function useSideMenu(): SideMenuData {
  const context = useContext(SideMenuContext);

  if (!context) {
    throw new Error('it should use within a SideMenuContext');
  }

  return context;
}

/* eslint-disable no-unused-expressions */
import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import PhoneInput from '../../components/PhoneInput';
import { Container } from './styles';

import Choice from '../../components/Choice';
import Input from '../../components/Input';
import DoubleInput from '../../components/DoubleInput';

import api from '../../services/api';

import { schema } from './schemas';

interface OptionProps {
  value: string;
  label?: string;
}

interface FormContent {
  [key: string]: unknown;
}

interface DataForm {
  week_days?: string;
  description?: string;
  start_hour?: number;
  whatsapp?: string | null;
  end_hour?: number;
}

interface FileType {
  url: string;
}

interface AdFile {
  id: number;
  file_id: number;
  ad_id: number;
  file: FileType;
}

interface AdType {
  week_days: number[];
  description: string;
  start_hour: number;
  whatsapp?: string | null;
  end_hour: number;
}

interface Error {
  path: string;
  message: string;
}

interface ValidationError {
  [key: string]: string;
}

export default function DataAd(): JSX.Element {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [nameLength, setNameLength] = useState(300);
  const [whatsapp, setWhatsapp] = useState('');
  const [initialData] = useState<DataForm>({});
  const [libZap, setLibZap] = useState<boolean>(false);

  const options = useMemo<OptionProps[]>(
    () => [
      {
        value: '0',
        label: 'Domingo',
      },
      {
        value: '1',
        label: 'Segunda',
      },
      {
        value: '2',
        label: 'Terça',
      },
      {
        value: '3',
        label: 'Quarta',
      },
      {
        value: '4',
        label: 'Quinta',
      },
      {
        value: '5',
        label: 'Sexta',
      },
      {
        value: '6',
        label: 'Sábado',
      },
      {
        value: '7',
        label: 'Todos os dias',
      },
    ],
    []
  );

  useEffect(() => {
    if (whatsapp && libZap) {
      formRef.current?.setData({ whatsapp });
    }
  }, [whatsapp, libZap]);

  const loadData = useCallback(async () => {
    const response = await api.get<AdType>('/ads-edit');
    const ad = response.data;

    if (!ad) {
      return;
    }

    if (ad.whatsapp) {
      setLibZap(true);
    }

    const { description, week_days, start_hour, end_hour, whatsapp: zap } = ad;

    if (zap) {
      setWhatsapp(zap);
    }

    const week_daysReduced = week_days?.reduce((acc, day) => acc + day, '');
    if (start_hour && end_hour) {
      formRef &&
        formRef.current &&
        formRef.current.setData({
          description,
          week_days: week_daysReduced,
          start_hour,
          end_hour,
        });
    } else {
      formRef &&
        formRef.current &&
        formRef.current.setData({
          description,
          week_days: week_daysReduced,
        });
    }
  }, []);

  // const handleRadioWhatsapp = useCallback(
  //   (value: ChangeEvent<HTMLInputElement>) => {
  //     value.target.value === 'Sim' ? setLibZap(true) : setLibZap(false);
  //     if (value.target.value === 'Não') {
  //       formRef.current?.setData({ whatsapp: '' });
  //     }
  //   },
  //   []
  // );

  useEffect(() => {
    async function loadDataLocal(): Promise<void> {
      try {
        await loadData();
      } catch (err) {
        toast.error('Falha ao carregar dados');
      }
    }
    loadDataLocal();
  }, [loadData]);

  const submit = useCallback(
    async (data: FormContent): Promise<void> => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        if (!libZap) {
          // eslint-disable-next-line no-param-reassign
          data.whatsapp = null;
        }

        await api.post('/ads-edit', data);

        history.push('/AdViewer');
      } catch (error) {
        const validationErrors: ValidationError = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err: Error) => {
            validationErrors[err.path] = err.message;
          });
          // eslint-disable-next-line no-unused-expressions
          formRef &&
            formRef.current &&
            formRef.current.setErrors(validationErrors);
          return;
        }
        toast.error('Falha ao cadastrar as informações. Verifique a conexão');
      }
    },
    [libZap, history]
  );
  return (
    <Container>
      <header>
        <h2>Estamos quase acabando!</h2>
        <br />
        <br />
        <h3>
          No 3º passo é onde estarão as informações sobre os dias e horários do
          seu Happy Hour
        </h3>

        <div className="steps">
          <ul>
            <li className="step">1º Passo</li>
            <li className="step">2º Passo</li>
            <li className="selected">3º Passo</li>
            <li className="step">4º Passo</li>
          </ul>
        </div>
      </header>
      <Form
        onSubmit={(data: FormContent): Promise<void> => submit(data)}
        initialData={initialData}
        translate="a"
        ref={formRef}
      >
        <div className="time">
          Horário do Happy Hour: das
          <DoubleInput name1="start_hour" name2="end_hour" mask="99:99" />
        </div>
        <Input
          name="description"
          multiline
          placeholder="Descrição do Happy Hour (Opcional)"
          className="two-columns"
          maxLength={300}
          onChange={() => {
            const name =
              (formRef.current?.getFieldValue('description') as string) || '';
            setNameLength(300 - name.length);
          }}
        />
        <span>
          {`${nameLength} `}
          caracteres restantes
        </span>
        <br />
        <div className="time">Dias da semana com Happy Hour:</div>
        <br />
        <Choice
          options={options}
          multiple
          name="week_days"
          className="choice"
        />

        {/* <div className="whatsapp">
          <h3>Happy Hour com Delivery</h3>
          <div>
            <input
              type="radio"
              id="whatsappLib"
              checked={libZap}
              value="Sim"
              onChange={handleRadioWhatsapp}
              data-testid="sim"
            />
            <span>Sim</span>
            <input
              type="radio"
              id="whatsappLib"
              onChange={handleRadioWhatsapp}
              checked={!libZap}
              value="Não"
              data-testid="nao"
            />
            <span>Não</span>
          </div>
        </div> */}
        {libZap && (
          <div className="phoneZap">
            <PhoneInput placeholder="Número para pedidos" name="whatsapp" />
          </div>
        )}

        <div className="divButton">
          <Link to="/FileAd">
            <button type="button" className="pageButton">
              Voltar
            </button>
          </Link>
          <button type="submit" className="pageButton">
            {' '}
            Próximo
          </button>
        </div>
      </Form>
    </Container>
  );
}

import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  float: left;
  display: block;
  min-height: 100%;
  background: #f5f5f5;
  position: fixed;
  left: 0;
  top: 64px;
  ul {
    width: 100%;
    margin: 10px auto;
    li {
      width: 100%;
    }
    button {
      margin-left: 5px;
      width: 100%;
      background-color: #f5f5f5;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 44px;
      border: 0;
      border-radius: 4px;
      margin: 0;
      .longDiv {
        display: grid;
        grid-template-columns: 60px 145px;
        /*grid-template-columns: 90px 140px; */
        width: 100%;
        align-items: center;
        justify-items: start;
      }
      .shortDiv {
        display: grid;
        grid-template-columns: 45px;
        /*grid-template-columns: 60px;*/
        width: 100%;
        align-items: center;
        justify-items: start;
      }

      img {
        margin: 0 5px;
        grid-column: 1;
      }
      span {
        font-size: 16px;
        grid-column: 2;
      }
    }
    button:hover {
      background-color: ${darken(0.3, '#f6f6f6')};
      span {
        color: #e73739;
      }
    }
  }
  .backButton {
    border: 0;
    position: fixed;
    bottom: 0;
    background-color: #f5f5f5;
  }
  .moreLeft {
    left: 27px;
  }
  .moreRight {
    left: 190px;
  }

  .long {
    width: 320px;
  }
  .short {
    width: 50px;
  }
  @media screen and (max-width: 870px) {
    display: none;
  }
`;

import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  border-radius: 20px;
  display: flex;
  background: #221e35;
  flex-direction: column;
  padding: 15px;
  .nomeInput {
    grid-area: 1 / 1 / 1 / 3;
    justify-self: stretch;
  }
  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;

    span {
      color: #f6f6f6;
    }

    input {
      background: #f6f6f6;
      border: 0;
      height: 44px;
      padding: 0 15px;
      color: #221e35;
      margin: 0 0 10px;
      border-radius: 25px 25px 25px 25px;
      &::placeholder {
        color: #4a4545;
      }
    }

    button {
      margin: 5px 0 0;
      height: 44px;
      background: #e73739;
      font-weight: bold;
      color: #f6f6f6;
      border: 0;
      border-radius: 25px 25px 25px 25px;
      font-size: 16px;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.03, '#e73739')};
      }
    }
    a {
      color: #fff;
      margin-top: 15px;
      font-size: 16px;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
    div {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      button {
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 13px 12px 12px 10px;
        height: 44px;
        min-width: 120px;
        background: #e73739;
        font-weight: bold;
        color: #f6f6f6;
        border: 0;
        border-radius: 25px 25px 25px 25px;
        font-size: 16px;
        transition: background 0.2s;
        &:hover {
          background: ${darken(0.03, '#e73739')};
        }
      }
    }
  }
`;

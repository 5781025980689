import axios, { AxiosInstance } from 'axios';

class Connection {
  public api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_URL || 'http://localhost:3333',
    });
  }
}

export default new Connection().api;

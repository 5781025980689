/* eslint-disable no-param-reassign */
import React, {
  createContext,
  useCallback,
  useState,
  useEffect,
  useContext,
} from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';

interface SignInData {
  email: string;
  password: string;
}

interface FacebookToken {
  userID: string;
  accessToken: string;
}

interface AuthState {
  token: string;
  restaurant: Restaurant;
}

interface AuthContextData {
  restaurant: Restaurant;
  signIn(credentials: SignInData): Promise<void>;
  signOut: () => void;
  updateRestaurant: (restaurant: Restaurant) => void;
}

interface Owner {
  name: string;
  cpf: string;
  email: string;
  phone: string;
}

export interface Restaurant {
  id: string;
  razao_social: string;
  nome_fantasia: string;
  cnpj: string;
  email: string;
  nome_rua: string;
  phone: string;
  cidade: string;
  avatar: string;
  latitude: number;
  longitude: number;
  cep: string;
  estado: string;
  bairro: string;
  pais: string;
  numero: string;
  avatar_url: string;
  owner: Owner;
  password?: string;
  passwordConfirmation?: string;
  old_password?: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [dataAuth, setDataAuth] = useState<AuthState>({} as AuthState);

  useEffect(() => {
    function loadStoragedData(): void {
      const token = sessionStorage.getItem('@AppyHour:token');
      const restaurant = sessionStorage.getItem('@AppyHour:restaurant');

      api.defaults.headers.Authorization = `Bearer ${token}`;

      if (token && restaurant) {
        setDataAuth({ token, restaurant: JSON.parse(restaurant) });
      }
    }

    loadStoragedData();
  }, []);

  const updateRestaurant = useCallback(
    (restaurant: Restaurant) => {
      delete restaurant.password;
      delete restaurant.passwordConfirmation;
      delete restaurant.old_password;
      setDataAuth({ token: dataAuth.token, restaurant });
      sessionStorage.setItem(
        '@AppyHour:restaurant',
        JSON.stringify(restaurant)
      );
    },
    [dataAuth.token]
  );

  const signOut = useCallback(() => {
    sessionStorage.removeItem('@AppyHour:token');
    sessionStorage.removeItem('@AppyHour:restaurant');
    setDataAuth({} as AuthState);
  }, []);

  const signIn = useCallback(async ({ email, password }) => {
    try {
      const response = await api.post('restaurants-session', {
        email,
        password,
      });
      const { token, restaurant } = response.data;

      sessionStorage.setItem('@AppyHour:token', token);
      sessionStorage.setItem(
        '@AppyHour:restaurant',
        JSON.stringify(restaurant)
      );

      api.defaults.headers.Authorization = `Bearer ${token}`;

      setDataAuth({ token, restaurant });
    } catch (err) {
      toast.error('Falha no login, verifique suas credenciais');
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        restaurant: dataAuth.restaurant,
        signIn,
        signOut,
        updateRestaurant,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

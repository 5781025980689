/* eslint-disable react/require-default-props */
import React, { Fragment, useEffect, useRef, useState, useMemo } from 'react';

import { useField } from '@unform/core';

interface OptionProps {
  value: string;
  label?: string;
}

interface Props {
  name: string;
  options: OptionProps[];
  multiple?: boolean;
}

type ChoiceProps = JSX.IntrinsicElements['input'] & Props;

export default function Choice({
  name,
  options,
  multiple,
  ...rest
}: ChoiceProps): JSX.Element {
  const { fieldName, registerField, error } = useField(name);
  const ref = useRef<HTMLInputElement[] | null[]>([]);
  const [values, setValues] = useState<string[]>([]);

  const nativeField = multiple ? 'checkbox' : 'radio';

  const setValue = (
    refValue: React.MutableRefObject<HTMLInputElement[]>,
    value: string
  ): void => {
    if (value) {
      const valuesInArray = value.split('');
      setValues(valuesInArray);
    }
  };

  const parseValue = (choiceRef: HTMLInputElement[]): string | string[] => {
    const valuesInput = choiceRef.filter((i) => i.checked).map((i) => i.value);
    const uniqueValue = valuesInput.length > 0 ? valuesInput[0] : '';
    return multiple ? valuesInput : uniqueValue;
  };

  useEffect(() => {
    ref.current = ref.current.slice(0, options.length);
    registerField({
      name: fieldName,
      path: '',
      ref: ref.current,
      // @ts-ignore
      getValue: parseValue,
      setValue,
      clearValue: (choiceRef: HTMLInputElement[]) => {
        choiceRef.forEach((input) => {
          // eslint-disable-next-line no-param-reassign
          input.checked = false;
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName]);

  const checked = useMemo(() => {
    return options.map((value, index) => !!values.includes(`${index}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.length]);

  return (
    <>
      <div {...rest}>
        {options.map(({ value, label }, idx) => {
          const checkboxId = `${fieldName}-${value}-${checked[idx]}`;
          return (
            <Fragment key={checkboxId}>
              <input
                ref={(el): void => {
                  ref.current[idx] = el;
                }}
                type={nativeField}
                id={checkboxId}
                name={fieldName}
                aria-label={checkboxId}
                value={value}
                defaultChecked={checked[idx]}
              />
              {label && <label htmlFor={checkboxId}>{label}</label>}
            </Fragment>
          );
        })}
      </div>

      {error && <span>{error}</span>}
    </>
  );
}

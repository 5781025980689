import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  padding-bottom: 50px;
  span {
    color: #f6f6f6;
    margin: 5px auto;
  }
  .divButton {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 450px) {
      flex-direction: column;
      a {
        display: flex;
        button {
          flex: 1;
        }
      }
    }
  }

  .pageButton {
    margin: 13px;
    height: 44px;
    background: #e73739;
    font-weight: bold;
    min-width: 120px;
    color: #f6f6f6;
    border: 0;
    border-radius: 25px 25px 25px 25px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, '#e73739')};
    }
    @media screen and (max-width: 450px) {
      margin: 20px;
      width: 80%;
    }
  }

  header {
    margin-top: 30px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    h2 {
      color: #f5f5f5;
      text-align: justify;
    }
    h3 {
      font-size: 17px;
      color: #f5f5f5;
      text-align: justify;
      line-height: 1.5em;
    }
    .steps {
      width: 100%;
    }
    ul {
      margin: 30px auto;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
    .step {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px 0 0;
      width: 130px;
      height: 44px;
      background: #f5f5f5;
      font-weight: bold;
      color: #221e35;
      border: 0;
      border-radius: 25px 25px 25px 25px;
      font-size: 16px;
    }
    .selected {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px 0 0;
      width: 180px;
      height: 62px;
      background: #f5f5f5;
      font-weight: bold;
      color: #221e35;
      border: 0;
      border-radius: 25px 25px 25px 25px;
      font-size: 20px;
    }
    @media screen and (max-width: 640px) {
      ul {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    div {
      margin: 10px 0;
      color: #f5f5f5;
    }
    label {
      color: #f5f5f5;
    }
    #description {
      height: 174px;
    }
    .time {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;

      input {
        margin: 5px 10px;
        width: 80px;
        height: 30px;
      }
    }

    .phoneZap {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      width: 100%;
      input {
        width: 250px;
        height: 40px;
      }
    }

    .whatsapp {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      div {
        margin-left: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        input {
          margin: 0;
        }
        span {
          margin: 0 8px;
        }
      }
    }

    input {
      background: #f6f6f6;
      border: 0;
      padding: 0 15px;
      color: #221e35;
      font-size: 18px;
      margin: 0 0 10px;
      border-radius: 25px 25px 25px 25px;
      &::placeholder {
        color: #4a4545;
      }
    }
    textarea {
      background: #f6f6f6;
      border: 0;
      padding: 10px 20px 20px 20px;
      color: #221e35;
      margin: 0 0 10px;
      border-radius: 25px 25px 25px 25px;
      font-size: 14px;
      font-family: Comfortaa;
      &::placeholder {
        color: #4a4545;
        font-size: 14px;
        font-family: Comfortaa;
      }
    }
    .choice {
      width: 70%;
      margin: 10px auto;
      display: grid;
      grid-template-columns: 1fr 6fr 1fr 6fr 1fr 6fr 1fr 6fr;
      row-gap: 15px;
      @media screen and (max-width: 490px) {
        grid-template-columns: 1fr 6fr;
        width: 40%;
      }
    }
  }
  display: flex;
  flex-direction: column;
`;

import React from 'react';

// import { Container } from './styles';

interface Category {
  id: string;
  restaurant_id: number;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  menuItens: MenuItem[];
}

interface MenuItem {
  id: string;
  category_id: number;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  description: string;
  normal_price?: string;
  happy_hour_price?: string;
}

interface PropsData {
  category: Category;
}

type Props = PropsData & JSX.IntrinsicElements['div'];

export default function Category({ category }: Props): JSX.Element {
  return (
    <>
      <div className="category">{category.name}</div>
      {category.menuItens.map((menuItem) => (
        <div className="menuItem" key={menuItem.name}>
          <div className="descriptionNome">
            <div>
              <h6>{menuItem.name}</h6>
            </div>
            <span>{menuItem.description}</span>
          </div>
          <div className="prices">
            <h6>
              De:
              {'\u00A0'}
              {menuItem.normal_price || '-'}
            </h6>
            <h6>
              Por:
              {'\u00A0'}
              {menuItem.happy_hour_price || '-'}
            </h6>
          </div>
        </div>
      ))}
    </>
  );
}

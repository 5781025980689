/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef, useCallback } from 'react';

import * as Yup from 'yup';

import { Link, useHistory } from 'react-router-dom';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { toast } from 'react-toastify';
import ImagePicker from '../../components/ImagePicker';

import api from '../../services/api';

import { Container } from './styles';

import { schema } from './schemas';

interface FormContent {
  [key: string]: unknown;
}

interface FileIdsType {
  ids: string[];
  urls: string[];
}

interface DataForm {
  file_ids?: FileIdsType;
}

interface FileType {
  url: string;
}

interface AdFile {
  id: number;
  file_id: number;
  ad_id: number;
  file: FileType;
}

interface Error {
  path: string;
  message: string;
}

interface ValidationError {
  [key: string]: string;
}

export default function FileAd(): JSX.Element {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [edit_ad_id, setEdit_ad_id] = useState('');
  const [initialData, setInitialData] = useState<DataForm>({});
  useEffect(() => {
    async function loadData(): Promise<void> {
      try {
        const response = await api.get('/ads-edit');
        let ad = response.data;
        if (!ad) {
          const responseAd = await api.post('/ads-edit');
          ad = responseAd.data;
        }

        setEdit_ad_id(ad.id);

        setInitialData({ file_ids: ad.images });
      } catch (err) {
        toast.error('Falha ao atualizar página. Verifique a conexão');
      }
    }

    loadData();
  }, []);

  const submit = useCallback(
    async (data: FormContent): Promise<void> => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        history.push('/DataAd');
      } catch (error) {
        const validationErrors: ValidationError = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err: Error) => {
            validationErrors[err.path] = err.message;
          });
          formRef &&
            formRef.current &&
            formRef.current.setErrors(validationErrors);
        }
      }
    },
    [history]
  );

  return (
    <Container>
      <header>
        <h2>Olá!</h2>
        <br />
        <br />
        <h3>
          No 2º passo, você irá selecionar as imagens que serão exibidas para os
          seus clientes. É permitido adicionar até 7 fotos, sendo que a
          primeira, de preferência, a logomarca do seu estabelecimento ou algo
          que o identifique. Vamos lá?!
        </h3>

        <div className="steps">
          <ul>
            <li className="step">1º Passo</li>
            <li className="selected">2º Passo</li>
            <li className="step">3º Passo</li>
            <li className="step">4º Passo</li>
          </ul>
        </div>
      </header>

      <Form
        onSubmit={(data: FormContent): Promise<void> => submit(data)}
        initialData={initialData}
        translate="a"
        ref={formRef}
      >
        {!!edit_ad_id && (
          <ImagePicker name="file_ids" edit_ad_id={edit_ad_id} />
        )}
        <div className="divButton">
          <Link to="/Dashboard">
            <button type="button" className="pageButton">
              Voltar
            </button>
          </Link>
          <button type="submit" className="pageButton">
            {' '}
            Próximo
          </button>
        </div>
      </Form>
    </Container>
  );
}

/* eslint-disable @typescript-eslint/camelcase */
import * as Yup from 'yup';

interface RestaurantInfo {
  razao_social: string;
  nome_fantasia: string;
  cnpj: string;
  phone: string;
  password?: string;
  passwordConfirmation?: string;
  old_password?: string;
  email: string;
}

interface RestaurantAddress {
  nome_rua: string;
  cidade: string;
  bairro: string;
  numero: string;
  estado: string;
  cep: string;
  pais: string;
}

interface RestaurantOwner {
  cpf: string;
  name: string;
  phone: string;
  email: string;
}

export const schemaInfo = Yup.object().shape<RestaurantInfo>({
  razao_social: Yup.string().required('Razão Social é obrigatório'),
  email: Yup.string()
    .required('E-mail é obrigatório')
    .email('Deve ser um formato de e-mail válido'),
  nome_fantasia: Yup.string().required('Nome Fantasia é obrigatório'),
  old_password: Yup.string(),
  password: Yup.string().when('old_password', {
    is: (val) => !!val.length,
    then: Yup.string()
      .required('Senha é obrigatória')
      .min(6, 'Tamanho mínimo da senha é de 6 caracteres')
      .matches(
        /^((([a-z]|[A-Z])+\d+)+)|((\d+([a-z]|[A-Z])+)+)$/,
        'A senha deve ter pelo menos uma letra e um número'
      ),
    otherwise: Yup.string().max(
      0,
      'Nova senha deve ser criada com o envio da antiga'
    ),
  }),

  passwordConfirmation: Yup.string().when('old_password', {
    is: (val) => !!val.length,
    then: Yup.string()
      .required('Confirmação de senha é obrigatória')
      .min(6, 'Tamanho mínimo da senha é de 6 caracteres')
      .oneOf([Yup.ref('password')], 'Deve ser igual à senha'),
    otherwise: Yup.string().max(
      0,
      'Nova senha deve ser criada com o envio da antiga'
    ),
  }),

  phone: Yup.string()
    .required('Telefone é obrigatório')
    .matches(
      /^(\+\d{1,2})?\(\d{2}\) ?\d{4,5}-\d{4}$/,
      'Deve ser no formato (XX) XXXXX-XXXX'
    ),
  cnpj: Yup.string()
    .required('CNPJ é obrigatório')
    .matches(
      /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/,
      'Deve ser no formato XX.XXX.XXX/XXXX-XX'
    ),
});

export const schemaAddress = Yup.object().shape<RestaurantAddress>({
  cep: Yup.string().required('CEP é obrigatório'),
  bairro: Yup.string().required('Bairro é obrigatório'),
  cidade: Yup.string().required('Cidade é obrigatório'),
  pais: Yup.string().required('País é obrigatório'),
  numero: Yup.string().required(
    'Número é obrigatório. Caso não tenha número, coloque S/N'
  ),
  nome_rua: Yup.string().required('Nome da rua é obrigatório'),
  estado: Yup.string().required('Escolha um dos estados'),
});

export const schemaOwner = Yup.object().shape<RestaurantOwner>({
  name: Yup.string().required('Nome é obrigatório'),
  email: Yup.string()
    .email('Deve ser um e-mail válido')
    .required('E-mail é obrigatório'),
  phone: Yup.string()
    .required('Telefone é obrigatório')
    .matches(
      /^(\+\d{1,2})?\(\d{2}\) ?\d{4,5}-\d{4}$/,
      'Deve ser no formato (XX) XXXXX-XXXX'
    ),
  cpf: Yup.string()
    .required('CPF é obrigatório')
    .matches(/^(\d{3}\.){2}\d{3}-\d{2}$/, 'Deve ser no formato XXX.XXX.XXX-XX'),
});

/* eslint-disable @typescript-eslint/camelcase */
import * as Yup from 'yup';

interface Category {
  name: string;
}

export const categorySchema = Yup.object().shape<Category>({
  name: Yup.string()
    .required('Nome é obrigatório')
    .max(50, 'Tamanho máximo: 50 caracteres'),
});

/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactStars from 'react-stars';
import { MdEdit, MdDelete } from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { parseISO, format } from 'date-fns';
import api from '../../services/api';
import schema from './schemas';
import Input from '../../components/Input';
import InputHidden from '../../components/InputHidden';

import { useAuth } from '../../hooks/auth';
import { Container, Comment, Answer } from './styles';

interface Comment {
  id: string;
  message: string;
  edited: boolean;
  created_at: string;
  formatted_date: string;
  rate: number;
  user: {
    name: string;
  };
  answer?: {
    id: string;
    edited: boolean;
    created_at: string;
    formatted_date: string;
    message: string;
  };
}

interface AnswerAPI {
  comments: Comment[];
}

interface OptionsAnswers {
  [key: string]: boolean;
}

interface ValidationError {
  [key: string]: string;
}

interface FormData {
  message: string;
  comment_id: string;
  answer_id?: string;
}

export default function DataAd(): JSX.Element {
  const [comments, setComments] = useState<Comment[]>([]);
  const formRef = useRef<FormHandles>(null);
  const [showForm, setShowForm] = useState<OptionsAnswers>(
    {} as OptionsAnswers
  );
  const [isEdit, setIsEdit] = useState<OptionsAnswers>({} as OptionsAnswers);
  const { restaurant } = useAuth();

  const updateData = useCallback(async () => {
    try {
      const response = await api.get<AnswerAPI>(`/comments/${restaurant.id}`);
      const localComments = response.data.comments;

      const formattedComments = localComments.map((comment) => {
        const dateComment = parseISO(comment.created_at);
        const formattedDateComment = format(dateComment, 'dd/MM/yyyy');
        const newComment = { ...comment };
        newComment.formatted_date = formattedDateComment;
        setShowForm((oldState) => {
          const newState = { ...oldState };
          newState[comment.id] = false;
          return newState;
        });

        setIsEdit((oldState) => {
          const newState = { ...oldState };
          newState[comment.id] = false;
          return newState;
        });

        if (newComment.answer) {
          const { answer } = newComment;
          const dateAnswer = parseISO(answer.created_at);
          const formattedDateAnswer = format(dateAnswer, 'dd/MM/yyyy');
          newComment.answer = {
            ...answer,
            formatted_date: formattedDateAnswer,
          };
        }
        return newComment;
      });
      setComments(formattedComments);
    } catch {
      toast.error('Não foi possível atualizar os comentários');
    }
  }, [restaurant.id]);
  useEffect(() => {
    updateData();
  }, [updateData]);

  const onSubmit = useCallback(
    async (data: FormData): Promise<void> => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        if (isEdit[data.comment_id]) {
          await api.put(`/answers/${data.answer_id}`, {
            message: data.message,
          });
        } else {
          await api.post('/answers', data);
        }

        updateData();
      } catch (error) {
        const validationErrors: ValidationError = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err: Error) => {
            validationErrors[error.path] = err.message;
          });
          // eslint-disable-next-line no-unused-expressions
          formRef &&
            formRef.current &&
            formRef.current.setErrors(validationErrors);
          return;
        }
        toast.error('Falha ao cadastrar as informações. Verifique a conexão');
      }
    },
    [updateData, isEdit]
  );

  const onDelete = useCallback(
    async (answerId: string) => {
      try {
        await api.delete(`/answers/${answerId}`);
        await updateData();
      } catch {
        toast.error('Falha ao deletar a resposta. Tente novamente');
      }
    },
    [updateData]
  );

  return (
    <Container>
      <p>
        Aqui estarão todos os comentários das pessoas que o visitaram. Nosso
        objetivo é que você possa fornecer uma excelente experiência para os
        seus clientes. Seja um elogio ou crítica, a idéia dos dos comentários é
        ajudá-lo a ter uma noção de como as pessoas se sentiram enquanto
        estiveram ali
      </p>
      {comments.map((comment) => (
        <Comment key={comment.id}>
          <header>
            <p>{comment.user.name.split(' ')[0]}</p>
            <ReactStars count={5} edit={false} value={comment.rate} />
            {comment.edited && <span>Editado</span>}

            <span>{comment.formatted_date}</span>
          </header>
          <div>
            <p>{comment.message}</p>
          </div>
          {comment.answer ? (
            showForm[comment.id] ? (
              <div className="formAnswer">
                <Form
                  onSubmit={onSubmit}
                  translate="no"
                  ref={formRef}
                  initialData={{ message: comment.answer.message }}
                >
                  <Input name="message" multiline maxLength={100} rows={4} />
                  <InputHidden name="answer_id" value={comment.answer.id} />
                  <InputHidden name="comment_id" value={comment.id} />
                  <button type="submit">Enviar Resposta</button>
                </Form>

                <button
                  type="button"
                  onClick={() =>
                    setShowForm((oldState) => {
                      const newState = { ...oldState };
                      newState[comment.id] = !oldState[comment.id];
                      return newState;
                    })
                  }
                >
                  {showForm[comment.id] ? 'Cancelar' : 'Editar'}
                </button>
              </div>
            ) : (
              <Answer>
                <header>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        setIsEdit((oldState) => {
                          const newState = { ...oldState };
                          newState[comment.id] = true;
                          return newState;
                        });
                        setShowForm((oldState) => {
                          const newState = { ...oldState };
                          newState[comment.id] = true;
                          return newState;
                        });
                      }}
                    >
                      <MdEdit />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        if (
                          window.confirm(
                            'Deseja realmente excluir esta categoria?'
                          )
                        ) {
                          onDelete(comment.answer?.id || '');
                        }
                      }}
                    >
                      <MdDelete />
                    </button>
                  </div>
                  {comment.answer.edited && <span>Editado</span>}
                  <span>{comment.answer.formatted_date}</span>
                </header>

                <p>{comment.answer.message}</p>
              </Answer>
            )
          ) : (
            <div className="formAnswer">
              {showForm[comment.id] && (
                <Form onSubmit={onSubmit} translate="no" ref={formRef}>
                  <Input name="message" multiline maxLength={100} rows={4} />
                  <InputHidden name="comment_id" value={comment.id} />
                  <button type="submit">Enviar Resposta</button>
                </Form>
              )}
              <button
                type="button"
                onClick={() =>
                  setShowForm((oldState) => {
                    const newState = { ...oldState };
                    newState[comment.id] = !oldState[comment.id];
                    return newState;
                  })
                }
              >
                {showForm[comment.id] ? 'Cancelar' : 'Responder'}
              </button>
            </div>
          )}
        </Comment>
      ))}
    </Container>
  );
}

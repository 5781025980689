import styled from 'styled-components';
import { Form } from '@unform/web';
import { darken } from 'polished';

export const QueryForm = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  max-width: 600px;
  width: 100%;
  position: relative;
  strong {
    font-weight: 500;
    color: #f6f6f6;
    margin: 8px auto;
  }
  input {
    background: #f6f6f6;
    border: 0;
    padding: 0 15px;
    color: #221e35;
    margin: 0 0 10px;
    border-radius: 25px 25px 25px 25px;
    height: 44px;
    width: 100%;
    &::placeholder {
      color: #e73739;
    }
  }

  div {
    position: absolute;
    top: calc(100% - 55px);
    width: 100%;
    ul {
      background: darken(0.1, #f6f6f6);
      width: 100%;
    }

    li {
      text-decoration: none;
      width: 100%;
      background: #f6f6f6;
      border-top: 1px solid;
      min-height: 44px;
      overflow: hidden;
      & + li {
        padding: 3px 0;
      }
      button {
        width: 100%;
        background: none;
        border: none;
        color: #221e35;
        min-height: 44px;
        &:hover {
          width: 100%;
          border-radius: 0;
          background: rgba(0, 0, 0, 0.2);
          border: none;
          color: #221e35;
        }
      }
    }
    .selected {
      background: #bbb;
    }
  }

  > button {
    /* Foram adicionados os atributos width e margin para diminuir o tamanho
      do botão acessar na página de login*/
    margin: auto;
    width: 100%;
    max-width: 240px;
    /* margin: 5px 0 0;*/
    min-height: 44px;
    background: #e73739;
    font-weight: bold;
    color: #f6f6f6;
    border: 0;
    border-radius: 25px 25px 25px 25px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, '#e73739')};
    }
  }
`;

export const Unform = styled(Form)`
  max-width: 450px;
  display: flex;
  flex-direction: column;
  margin: 30px auto;

  span {
    color: #f6f6f6;
    text-align: justify;
    margin-bottom: 5px;
  }

  input {
    height: 44px;
    background: #f6f6f6;
    border: 0;
    padding: 0 15px;
    color: #221e35;
    margin: 0 0 10px;
    border-radius: 25px 25px 25px 25px;
    &::placeholder {
      color: #e73739;
    }
  }
  button {
    /* Foram adicionados os atributos width e margin para diminuir o tamanho
      do botão acessar na página de login*/
    width: 100%;
    max-width: 240px;
    margin: auto;
    /* margin: 5px 0 0;*/
    min-height: 44px;
    background: #e73739;
    font-weight: bold;
    color: #f6f6f6;
    border: 0;
    border-radius: 25px 25px 25px 25px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, '#e73739')};
    }
  }
  a {
    color: #fff;
    margin-top: 15px;
    font-size: 16px;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
`;

import styled from 'styled-components';
import { Form } from '@unform/web';
import { darken } from 'polished';

export const Unform = styled(Form)`
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  align-items: center;

  span {
    color: #f6f6f6;
    margin-bottom: 5px;
  }

  select {
    background: #f6f6f6;
    border: 0;
    height: 44px;
    padding: 0 15px;
    margin: 0 0 10px;
    border-radius: 0 25px 0 25px;
    color: #e73739;
  }

  select.selected {
    color: #221e35;
  }

  input.usual {
    height: 44px;
  }
  input {
    background: #f6f6f6;
    border: 0;
    width: 100%;
    max-width: 450px;
    padding: 0 15px;
    color: #221e35;
    margin: 0 0 10px;
    border-radius: 25px 25px 25px 25px;
    &::placeholder {
      color: #e73739;
    }
  }
  button {
    /* Foram adicionados os atributos width e margin para diminuir o tamanho
    do botão acessar na página de login*/
    margin: auto;
    /* margin: 5px 0 0;*/
    height: 44px;
    width: 100%;
    max-width: 240px;
    background: #e73739;
    font-weight: bold;
    color: #f6f6f6;
    border: 0;
    border-radius: 25px 25px 25px 25px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, '#e73739')};
    }
  }
  a {
    color: #fff;
    margin-top: 15px;
    font-size: 16px;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
`;

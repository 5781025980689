/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect, RefObject } from 'react';
import InputMask from 'react-input-mask';

import { useField } from '@unform/core';

interface Props {
  name: string;
  label?: string;
  mask: string;
}

declare type InputMaskProps = JSX.IntrinsicElements['input'] & Props;

export default function InputMaskUnform({
  name,
  label,
  mask,
  ...rest
}: InputMaskProps): JSX.Element {
  const ref = useRef<InputMask>(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: fieldName,
        ref: ((ref as unknown) as RefObject<HTMLInputElement>)
          .current as HTMLInputElement,
        path: 'value',
      });
    }
  }, [ref.current, fieldName]); // eslint-disable-line

  const props = {
    ...rest,
    ref,
    id: fieldName,
    name: fieldName,
    'aria-label': fieldName,
    defaultValue,
  };

  return (
    <>
      {label && <label htmlFor={fieldName}>{label}</label>}

      <InputMask {...props} mask={mask} />

      {error && <span>{error}</span>}
    </>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useCallback, useState } from 'react';
import { cpf } from 'cpf-cnpj-validator';
import { toast } from 'react-toastify';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Unform } from './styles';
import Input from '../../../components/Input';
import InputMask from '../../../components/InputMask';
import PhoneInput from '../../../components/PhoneInput';
import { schemaOwner } from '../schemas/schemas';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';
import ValidationTypeError from '../../../errors/ValidationTypeError';

interface RestaurantOwner {
  cpf: string;
  name: string;
  phone: string;
  email: string;
}

interface Error {
  path: string;
  message: string;
}

interface ValidationError {
  [key: string]: string;
}

export default function FormOwner(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const { restaurant, updateRestaurant } = useAuth();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: RestaurantOwner) => {
      try {
        setLoading(true);
        // eslint-disable-next-line no-unused-expressions
        formRef && formRef.current && formRef.current.setErrors({});
        await schemaOwner.validate(data, {
          abortEarly: false,
        });

        if (!cpf.isValid(data.cpf)) {
          throw new ValidationTypeError('cpf');
        }

        await api.put('/owners', data);

        updateRestaurant({ ...restaurant, owner: data });

        toast.success('Atualizado com sucesso');

        // eslint-disable-next-line no-unused-expressions
      } catch (error) {
        const validationErrors: ValidationError = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err: Error) => {
            validationErrors[err.path] = err.message;
          });
          // eslint-disable-next-line no-unused-expressions
          formRef &&
            formRef.current &&
            formRef.current.setErrors(validationErrors);
          return;
        }
        if (error instanceof ValidationTypeError) {
          validationErrors[error.field] = error.message;
          // eslint-disable-next-line no-unused-expressions
          formRef &&
            formRef.current &&
            formRef.current.setErrors(validationErrors);
        }
        toast.error('Falha ao atualizar dados');
      } finally {
        setLoading(false);
      }
    },
    [restaurant, updateRestaurant]
  );

  return (
    <>
      <Unform
        initialData={restaurant.owner}
        onSubmit={handleSubmit}
        translate="a"
        ref={formRef}
      >
        <Input className="usual" name="name" placeholder="Nome*" />
        <InputMask
          name="cpf"
          mask="999.999.999-99"
          placeholder="CPF*"
          className="usual"
        />
        <PhoneInput name="phone" placeholder="Telefone*" className="usual" />
        <Input
          name="email"
          className="usual"
          placeholder="E-mail*"
          type="email"
        />
        <span>Todos os campos são obrigatórios</span>
        <br />

        <button type="submit">
          {loading ? 'Salvando...' : 'Salvar alterações'}
        </button>
      </Unform>
    </>
  );
}

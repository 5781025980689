/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { useHistory, Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { cnpj } from 'cpf-cnpj-validator';
import Input from '../../../../components/Input';
import InputPassword from '../../../../components/PasswordInput';
import { useSignUp } from '../../../../hooks/signUp';
import logo from '../../../../assets/logotipo.png';
import InputMask from '../../../../components/InputMask';
import PhoneInput from '../../../../components/PhoneInput';
import { schemaInfo } from '../../schemas/schemas';
import ValidationTypeError from '../../../../errors/ValidationTypeError';

interface Props {
  initialData: object;
}

interface Error {
  path: string;
  message: string;
}

interface ValidationError {
  [key: string]: string;
}

interface RestaurantInfo {
  razao_social: string;
  nome_fantasia: string;
  cnpj: string;
  phone: string;
  especialidade: string;
  password: string;
  passwordConfirmation: string;
  email: string;
}

export default function FormInfo(): JSX.Element {
  const { restaurant, updateRestaurant } = useSignUp();

  const classOne = 'form-progress-indicator one active';
  const classTwo = 'form-progress-indicator two';
  const classThree = 'form-progress-indicator three';
  const classFour = 'form-progress-indicator four';
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: RestaurantInfo) => {
      try {
        // eslint-disable-next-line no-unused-expressions
        formRef && formRef.current && formRef.current.setErrors({});
        await schemaInfo.validate(data, {
          abortEarly: false,
        });

        if (!cnpj.isValid(data.cnpj)) {
          throw new ValidationTypeError('cnpj');
        }

        const newRestaurant = {
          ...restaurant,
          ...data,
        };
        updateRestaurant(newRestaurant);
        history.push('register-address');
      } catch (error) {
        const validationErrors: ValidationError = {};
        if (error instanceof Yup.ValidationError) {
          error.inner.forEach((err: Error) => {
            validationErrors[err.path] = err.message;
          });
          // eslint-disable-next-line no-unused-expressions
          formRef &&
            formRef.current &&
            formRef.current.setErrors(validationErrors);
        }
        if (error instanceof ValidationTypeError) {
          validationErrors[error.field] = error.message;
          // eslint-disable-next-line no-unused-expressions
          formRef &&
            formRef.current &&
            formRef.current.setErrors(validationErrors);
        }
      }
    },
    [updateRestaurant, history, restaurant]
  );

  return (
    <>
      <img src={logo} width="250" alt="appy-hour" />

      <div className="form-progress">
        <progress className="form-progress-bar" max="100" value={0} />

        <div className={classOne} />
        <div className={classTwo} />
        <div className={classThree} />
        <div className={classFour} />
      </div>
      <h3>Informações do Estabelecimento</h3>
      <Form
        initialData={restaurant}
        onSubmit={handleSubmit}
        translate="a"
        ref={formRef}
      >
        <Input
          className="usual"
          name="razao_social"
          placeholder="Razão Social*"
        />
        <Input
          className="usual"
          name="nome_fantasia"
          placeholder="Nome Fantasia*"
        />
        <InputMask
          name="cnpj"
          className="usual"
          mask="99.999.999/9999-99"
          placeholder="CNPJ*"
        />
        <Input
          className="usual"
          name="email"
          placeholder="E-mail de acesso*"
          type="email"
        />
        <PhoneInput name="phone" placeholder="Telefone*" className="usual" />
        <InputPassword
          className="usual"
          name="password"
          placeholder="Senha*"
          autoComplete="new-password"
        />
        <InputPassword
          name="passwordConfirmation"
          className="usual"
          placeholder="Confirmação de Senha*"
          autoComplete="new-password"
        />
        <span>Todos os campos são obrigatórios</span>
        <br />

        <button type="submit">Avançar</button>
      </Form>
      <Link to="/">Já tem conta?</Link>
      <br />
      <br />
      <br />
      <br />
    </>
  );
}

import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-items: flex-end;
  p {
    margin-top: 50px;
    color: #f6f6f6;
    font-size: 25px;
    text-align: justify;
    margin-bottom: 100px;
  }
`;

export const Comment = styled.div`
  margin-bottom: 20px;
  background-color: #f6f6f6;
  flex-direction: column;
  width: 600px;
  border-radius: 10px;
  align-items: flex-end;

  header {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 10px;
    button {
      margin: 0 5px;
      border: 0;
      background: transparent;
    }
  }
  .formAnswer {
    flex-direction: column;
    display: flex;
    align-items: flex-end;
    width: 100%;
    button {
      /* Foram adicionados os atributos width e margin para diminuir o tamanho
      do botão acessar na página de login*/
      width: 120px;
      /* margin: 5px 0 0;*/
      height: 44px;
      background: #e73739;
      font-weight: bold;
      color: #f6f6f6;
      border: 0;
      border-radius: 25px 25px 25px 25px;
      font-size: 16px;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.03, '#e73739')};
      }
      margin-bottom: 30px;
    }
  }
  textArea {
    width: 100%;
    margin-bottom: 10px;
    resize: none;
  }
  form {
    flex-direction: column;
    display: flex;
    align-items: flex-end;
    width: 100%;
  }
  p {
    color: #000;
    font-size: 15px;
    margin: 0;
  }
  span {
    color: #000;
    font-size: 10px;
  }
  div {
    padding: 10px;
    p {
      text-align: justify;
    }
  }
  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Answer = styled.div`
  background-color: #ccc;
  flex-direction: column;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 5px;
  header {
    display: flex;
    justify-content: space-between;
  }
  p {
    color: #000;
    font-size: 15px;
    margin: 0;
  }
  span {
    color: #000;
    font-size: 10px;
  }
`;

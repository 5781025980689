import styled from 'styled-components';
import { darken } from 'polished';

export const Items = styled.ul`
  display: grid;
  grid-gap: 15px;

  li {
    .buttonItems {
      width: 150px;
    }

    .divPrice {
      min-width: 450px;
      justify-content: space-between;
    }

    button {
      background: rgba(0, 0, 0, 0);
      &:hover {
        background: ${darken(0.03, '#fff')};
      }
    }
    display: flex;
    justify-content: space-between;
    background: #fff;
    min-height: 44px;
    padding: 0;
    align-items: center;
    border-radius: 25px;
    strong {
      color: #221e35;
      font-size: 18px;
    }
    div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span {
        color: #221e35;
        font-size: 16px;
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  @media screen and (max-width: 860px) {
    li {
      .buttonItems {
        max-width: 150px;
        justify-content: center;
      }

      .divPrice {
        min-width: 50px;
        margin-top: 10px;
        justify-content: center;
      }
    }
  }

  @media screen and (max-width: 580px) {
    li {
      .buttonItems {
        max-width: 50px;
        justify-content: center;
      }

      .divPrice {
        min-width: 50px;
        margin-top: 10px;
        flex-direction: column;
      }
    }
  }
`;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';

import AuthLayout from '../pages/_layouts/auth';
import DefaultLayout from '../pages/_layouts/default';

import { useAuth } from '../hooks/auth';

type Props = {
  component: (props: any) => JSX.Element;
  isPrivate?: boolean;
  [x: string]: any;
};

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}: Props): JSX.Element {
  const { restaurant } = useAuth();

  if (!restaurant && isPrivate) {
    return <Redirect to="/" />;
  }

  if (restaurant && !isPrivate) {
    return <Redirect to="/dashboard" push />;
  }

  const Layout = restaurant ? DefaultLayout : AuthLayout;
  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps): JSX.Element => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

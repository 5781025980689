import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import { FiEye, FiEyeOff } from 'react-icons/fi';

interface Props<T> {
  name: string;
  label?: string;
  multiline?: T;
}
type InputProps = JSX.IntrinsicElements['input'] & Props<false>;
type TextAreaProps = JSX.IntrinsicElements['textarea'] & Props<true>;
export default function Input({ name, ...rest }: InputProps): JSX.Element {
  const ref = useRef<HTMLInputElement>(null);
  const [showText, setShowText] = useState(false);
  const { fieldName, defaultValue = '', registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const props = {
    ...rest,
    ref,
    id: fieldName,
    name: fieldName,
    'aria-label': fieldName,
    defaultValue,
  };
  return (
    <>
      <div
        style={{
          margin: 0,
          display: 'flex',
          background: '#f6f6f6',
          borderRadius: 30,
          height: 44,
          marginBottom: 10,
        }}
      >
        <input
          {...props}
          style={{ flex: 1 }}
          type={showText ? 'text' : 'password'}
        />
        {showText ? (
          <button
            type="button"
            onClick={() => setShowText(false)}
            style={{ background: 'transparent', width: 30, paddingRight: 8 }}
          >
            <FiEyeOff name="eye-off" size={20} color="#333333" />
          </button>
        ) : (
          <button
            type="button"
            onClick={() => setShowText(true)}
            style={{ background: 'transparent', width: 30, paddingRight: 8 }}
          >
            <FiEye name="eye" size={20} color="#333" />
          </button>
        )}
      </div>

      {error && <span>{error}</span>}
    </>
  );
}

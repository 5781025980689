/* eslint-disable @typescript-eslint/camelcase */
import * as Yup from 'yup';

interface ChangePassword {
  password: string;
  password_confirmation: string;
}

export const schemaPassword = Yup.object().shape<ChangePassword>({
  password: Yup.string()
    .required('Senha é obrigatória')
    .min(6, 'Tamanho mínimo da senha é de 6 caracteres')
    .matches(
      /^((([a-z]|[A-Z])+\d+)+)|((\d+([a-z]|[A-Z])+)+)$/,
      'A senha deve ter pelo menos uma letra e um número'
    ),
  password_confirmation: Yup.string()
    .required('Confirmação de senha é obrigatória')
    .min(6, 'Tamanho mínimo da senha é de 6 caracteres')
    .oneOf([Yup.ref('password')], 'Deve ser igual à senha'),
});

import React, { useState } from 'react';
import FormAddress from './FormAddress';
import FormInfo from './FormInfo';
import FormOwner from './FormOwner';

import { Container, Buttons } from './styles';

type FormToShow = 'address' | 'company' | 'owner';

function Profile(): JSX.Element {
  const [showForm, setShowForm] = useState<FormToShow>('company');
  return (
    <Container>
      <Buttons>
        <button type="button" onClick={() => setShowForm('company')}>
          Dados da empresa
        </button>
        <button type="button" onClick={() => setShowForm('address')}>
          Endereço da empresa
        </button>
        <button type="button" onClick={() => setShowForm('owner')}>
          Responsável pela empresa
        </button>
      </Buttons>
      {showForm === 'address' && <FormAddress />}
      {showForm === 'company' && <FormInfo />}
      {showForm === 'owner' && <FormOwner />}
    </Container>
  );
}

export default Profile;

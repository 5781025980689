import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 100%;
  background: linear-gradient(#e73739, #3b171f);
  display: flex;
  flex-direction: column;
  .expanded {
    padding-left: 260px;
  }
  .notExpanded {
    padding-left: 96px;
  }
  @media screen and (max-width: 870px) {
    .expanded {
      padding-left: 5px;
    }
    .notExpanded {
      padding-left: 5px;
    }
  }
`;

export const Content = styled.div`
  min-height: 100%;
  display: flex;
  flex: 1;
  margin-top: 64px;
  padding: 0;
  width: 100%;
  padding-right: 40px;
`;

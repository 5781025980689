import styled from 'styled-components';
import { darken } from 'polished';

export const Wrapper = styled('div')`
  min-height: 100%;
  background: linear-gradient(#e73739, #3b171f);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  text-align: center;
  .form-progress {
    position: relative;
    display: block;
    margin: 3rem auto;
    width: 100%;
    max-width: 400px;
  }

  img {
    margin-top: 30px;
  }

  #overlay {
    div {
      margin: 30px;
      color: #f6f6f6;
    }
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    overflow: auto;
    right: 0;
    bottom: 0;
    background-color: rgba(34, 30, 53, 0.9); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }

  h3 {
    color: #f6f6f6;
  }

  progress {
    display: block;
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    background: #e73739;
    width: 100%;
    height: 5px;
    background: none;
    transition: 1s;
    will-change: contents;

    &::-webkit-progress-bar {
      background-color: #ddd;
    }

    &::-webkit-progress-value {
      background-color: #e73739;
      transition: all 0.5s ease-in-out;
    }
  }

  .form-progress-indicator {
    position: absolute;
    top: -8px;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: white;
    border: 3px solid #ddd;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    transition-delay: 0.3s;
    will-change: transform;

    &.one {
      left: 0;
    }
    &.two {
      left: 33%;
    }
    &.three {
      left: 66%;
    }
    &.four {
      left: 95%;
    }
    &.active {
      animation: bounce 0.5s forwards;
      animation-delay: 0.5s;
      border-color: #e73739;
    }
  }

  a {
    color: #fff;
    margin-top: 15px;
    font-size: 16px;
    margin-bottom: 30px;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
  form {
    max-width: 315px;
    display: flex;
    flex-direction: column;
    margin: 30px auto;

    span {
      color: #f6f6f6;
    }

    select {
      background: #f6f6f6;
      border: 0;
      height: 44px;
      padding: 0 15px;
      margin: 0 0 10px;
      border-radius: 0 25px 0 25px;
      color: #e73739;
    }

    select.selected {
      color: #221e35;
    }

    input.usual {
      height: 44px;
    }
    input {
      background: #f6f6f6;
      border: 0;
      padding: 0 15px;
      color: #221e35;
      margin: 0 0 10px;
      border-radius: 25px 25px 25px 25px;
      &::placeholder {
        color: #e73739;
      }
      :disabled {
        background-color: #bbb;
      }
    }
    .estados {
      background: #f6f6f6;
      border: 0;
      padding: 0 0 0 5px;
      color: #221e35;
      margin: 0 0 10px;
      border-radius: 25px 25px 25px 25px;
      .react-select__control {
        background: #f6f6f6;
        border: none;
        border-radius: 25px 25px 25px 25px;
        box-shadow: none;
      }
      .react-select__placeholder {
        color: #e73739;
      }
    }
    button {
      /* Foram adicionados os atributos width e margin para diminuir o tamanho
      do botão acessar na página de login*/
      width: 50%;
      margin: auto;
      /* margin: 5px 0 0;*/
      height: 44px;
      background: #e73739;
      font-weight: bold;
      color: #f6f6f6;
      border: 0;
      border-radius: 25px 25px 25px 25px;
      font-size: 16px;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.03, '#e73739')};
      }
    }
    a {
      color: #fff;
      margin-top: 15px;
      font-size: 16px;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
    .divButtonPage {
      display: flex;
      width: 100%;
      button {
        margin: 5px 15px;
        height: 44px;
        flex: 1;
        background: #e73739;
        font-weight: bold;
        color: #f6f6f6;
        border: 0;
        border-radius: 25px 25px 25px 25px;
        font-size: 16px;
        transition: background 0.2s;
        &:hover {
          background: ${darken(0.03, '#e73739')};
        }
      }
    }
  }

  .link {
    color: #f6f6f6;
    font-style: bold;
    background: none;
    flex: 1;
    padding: 5px;
  }

  .checks {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }

  .terms {
    margin: 0;
    border-radius: 0;
  }
`;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { Link } from 'react-router-dom';

import { MdAddCircleOutline, MdSync } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Container, Categories } from './styles';
import api from '../../services/api';

import CategoryForm from './components/CategoryForm';
import CategoryItem from './components/CategoryItem';

interface MenuItem {
  name: string;
  id: string;
  description?: string;
  normal_price?: string;
  happy_hour_price?: string;
  category_id: string;
}

interface Category {
  name: string;
  id: string;
  menuItens: MenuItem[];
}

export default function MenuNew(): JSX.Element {
  const [categories, setCategories] = useState<Category[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/categories-edit');
      setLoading(false);
      setCategories(response.data);
    } catch (error) {
      toast.error('Falha ao atualizar');
    }
  }, []);

  useEffect(() => {
    async function localLoadData(): Promise<void> {
      await loadData();
    }
    localLoadData();
  }, []);

  const addItem = useCallback(async (): Promise<void> => {
    try {
      await loadData();
    } catch (err) {
      toast.error('Falha ao adicionar item');
    }
  }, []);

  const deleteItem = useCallback(async (category: Category): Promise<void> => {
    try {
      await api.delete(`/categories-edit/${category.id}`);
      loadData();
    } catch (err) {
      toast.error('Falha ao deletar a categoria');
    }
  }, []);

  const hasMenuItems = useMemo(() => {
    return categories.reduce(
      (acc, category) => category.menuItens.length > 0 && acc,
      true
    );
  }, [categories]);
  return (
    <Container>
      <header>
        <h2>Seja bem-vindo ao 1º passo.</h2>
        <br />
        <br />

        <h3>
          Aqui você irá montar o seu cardápio de Happy Hour. Comece informando
          as categorias e depois os itens, qualquer dúvida clique no botão de
          ajuda.
        </h3>

        <div className="steps">
          <ul>
            <li className="selected">1º Passo</li>
            <li className="step">2º Passo</li>
            <li className="step">3º Passo</li>
            <li className="step">4º Passo</li>
          </ul>
        </div>

        <div className="categoriesTitle">
          <div>
            <strong>Categorias & Itens</strong>
          </div>
          <button
            type="button"
            onClick={(): void => setShowForm(true)}
            className="addButton"
          >
            <MdAddCircleOutline size={24} color="#FFF" />
            Adicionar Categoria
          </button>
        </div>
      </header>
      {showForm && (
        <CategoryForm
          data={{ name: '', id: '', menuItens: [] }}
          handleCancel={(): void => setShowForm(false)}
          edit={false}
          addHandle={addItem}
        />
      )}
      <Categories>
        {loading && (
          <div className="loading">
            <MdSync size={24} color="#fff" />
          </div>
        )}
        {categories.map((category) => (
          <CategoryItem
            data={category}
            handleDelete={deleteItem}
            key={`${category.id}`}
            loadHandle={addItem}
          />
        ))}
      </Categories>
      <div className="divButton">
        <Link to="FileAd">
          <button
            className="pageButton"
            type="button"
            disabled={categories.length === 0 || !hasMenuItems}
          >
            Próximo
          </button>
        </Link>
      </div>
    </Container>
  );
}

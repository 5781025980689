/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Header from '../../../components/Header';
import Side from '../../../components/Side';
import { Wrapper, Content } from './styles';
import { useSideMenu } from '../../../hooks/sideMenu';

type Props = {
  children: JSX.Element;
};

export default function DefaultLayout({ children }: Props): JSX.Element {
  const { expanded } = useSideMenu();

  return (
    <Wrapper>
      <Header />
      <Side />
      <Content className={expanded ? 'expanded' : 'notExpanded'}>
        {children}
      </Content>
    </Wrapper>
  );
}

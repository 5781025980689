import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { MdMenu } from 'react-icons/md';

import { Container } from './styles';

import { useAuth } from '../../../../hooks/auth';

import logo from '../../../../assets/logotipo-small.png';

interface Props {
  className: string;
}

export default function Hamburguer(props: Props): JSX.Element {
  const { className } = props;
  const [showItems, setShowItems] = useState(false);

  const { signOut } = useAuth();

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);
  return (
    <Container className={className}>
      <div className="Menu">
        <button
          type="button"
          onClick={() => setShowItems(!showItems)}
          data-testid="buttonMenu"
        >
          <MdMenu color="#fff" size={44} />
        </button>
        <img src={logo} alt="GoBarber" className="mobile" />
      </div>
      {showItems && (
        <div className="items">
          <ul>
            <li>
              <Link to="/dashboard">Cardápios</Link>
            </li>
            <li>
              <Link to="/comments">Comentários</Link>
            </li>
            <li>
              <Link to="/profile">Perfil</Link>
            </li>
            <li>
              <button type="button" onClick={handleSignOut}>
                Sair
              </button>
            </li>
          </ul>
        </div>
      )}
    </Container>
  );
}
